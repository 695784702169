<template>
<div>
    <NavBar />
    <div class="matricula">
        <div class="matricula-card">
            <h3 class="matricula-title">Digite a matrícula para continuar</h3>
            <h3 v-if="msg_error" class="msg_error">{{text_msg_error}}</h3>
            <br>
            <form class="matricula-form" action="javascript:void(0);" @submit="sendMatricula">
                <div class="input-container">
                    <Input name="matricula" placeholder="Matrícula" />
                </div>
                <div class="btn-container">
                    <Button buttonText="Continuar" />
                </div>
            </form>
            <div class="btn-container">
                <Button buttonText="Cancelar" buttonColor="red" linkTo="/" />
            </div>
        </div>
    </div>
    <div v-if="loader == true" class="pre_loader">
        <button class="btn btn-primary btn_pre_loader" type="button" disabled>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Processando ...
        </button>
    </div>
    <Footer />
</div>
</template>

<script>

import { baseApiUrl } from "@/global";

import axios from '@/con'

import Button from "@/components/Button";
import Input from "@/components/Input";
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";

export default {
    name: "Matricula",
    data: function () {
        return {
            loader:false,
            msg_error: false,
            text_msg_error: ''
        }
    },
    components: {
        Button,
        Input,
        NavBar,
        Footer
    },
    methods: {
        sendMatricula(e) {

            this.loader = true
            this.msg_error = false

            //Recepção dos dados do Form
            let userData = {};

            var user = JSON.parse(localStorage.getItem('userKey'))

            const form = e.target;
            const formData = new FormData(form);
            for (const [inputName, value] of formData) {
                userData[inputName] = value;
            }

            if (!userData["matricula"]) {
                this.loader = false
                this.text_msg_error = 'O campo matrícula é obrigatório!'
                this.msg_error = true
            } else {
                let url = window.location.href;
                url = url.substring(url.indexOf("?") + 1, url.length);

                // Redirecionamento para página de "Redirecionar"... Mensagem que aparece, e cor depois do #
                if (url == "cadastroSenha") {

                    const method = 'post'
                    var matriculation = userData["matricula"]
                    var rotas = 'listCollaboratorOne'

                    const options = {
                        headers: {
                            "Accept": "application/json",
                            Authorization: `Bearer ${user.token}`
                        }
                    }

                    var data = {
                        matriculation:matriculation
                    }

                    axios[method](`${baseApiUrl}/${rotas}`, data, options,{
                        timeout: 5000
                    }).then(res => {

                        console.log(res.data)

                        this.loader = false

                        var id = res.data.listCollaboratorOne[0].id

                        var password = res.data.listCollaboratorOne[0].password

                        if (res.data.listCollaboratorOne != 'vazio') {

                            if (!password) {

                                this.msg_error = false

                                this.$router.push({
                                    path: `/password?number_1,${id}`
                                })
                            } else {
                                this.text_msg_error = 'Senha já cadastrada!'
                                this.msg_error = true
                            }
                        } else {
                            this.text_msg_error = 'Matrícula não encontrada!'
                            this.msg_error = true
                        }
                    }).catch((e) => {

                        this.loader = false

                        if (e.code === 'ECONNABORTED') {

                        }
                    });
                }
                if (url == "cadastroBiometria") {

                    const method = 'get'
                    var matriculation = userData["matricula"]
                    var rotas = 'list_people'

                    const options = {
                        headers: {
                            "Accept": "application/json"
                        }
                    }

                    axios[method](`${baseApiUrl}/${rotas}/${matriculation}`, {
                        timeout: 5000
                    }, options).then(res => {

                        this.loader = false

                        var id = res.data.people[0].people_id

                        if (res.data.people != 'vazio') {

                            this.msg_error = false

                            this.$router.push({
                                path: `/biometria?${matriculation}`
                            })


                        } else {
                            this.text_msg_error = 'Matrícula não encontrada!'
                            this.msg_error = true
                        }

                    }).catch((e) => {

                        this.loader = false

                        if (e.code === 'ECONNABORTED') {

                        }
                    });
                }
                if (url == "cadastroCracha") {

                    const method = 'post'
                    var matriculation = userData["matricula"]
                    var rotas = 'collaborator_list_matriculation'

                    const options = {
                        headers: {
                            "Accept": "application/json",
                            Authorization: `Bearer ${user.token}`
                        }
                    }

                    var data = {
                        matriculation:matriculation
                    }

                    axios[method](`${baseApiUrl}/${rotas}`, data, options, {
                        timeout: 5000
                    }).then(res => {

                        this.loader = false

                        var id = res.data.resultCollaborator.id

                        var tag_name = res.data.resultCollaborator.tag_name

                        if (res.data.resultCollaborator != 'vazio') {

                            if (!tag_name) {

                                this.msg_error = false

                                this.$router.push({
                                    path: `/cardrfid?number_2,${id}`
                                })
                            } else {
                                this.text_msg_error = 'Cartão já cadastrado!'
                                this.msg_error = true
                            }
                        } else {
                            this.text_msg_error = 'Matrícula não encontrada!'
                            this.msg_error = true
                        }

                    }).catch((e) => {

                        this.loader = false

                        if (e.code === 'ECONNABORTED') {

                        }
                    });
                }
                if (url == "retirada") {

                    var user = JSON.parse(localStorage.getItem('userKey'))

                    const method = 'post'
                    var matriculation = userData["matricula"]
                    var rotas = 'listCollaboratorOne'

                    const options = {
                        headers: {
                            "Accept": "application/json",
                            Authorization: `Bearer ${user.token}`
                        }
                    }

                    var data = {
                        matriculation:matriculation
                    }

                    axios[method](`${baseApiUrl}/${rotas}`, data, options,{
                        timeout: 5000
                    }).then(res => {

                        console.log(res.data)

                        this.loader = false

                        if(res.data.listCollaboratorOne.length > 0){

                            var matriculation = res.data.listCollaboratorOne[0].matriculation

                            if (res.data.listCollaboratorOne[0].id > 0) {

                                this.msg_error = false

                                this.$router.push({
                                    path: `passwordVerify?${matriculation}`
                                    //path: `/biometricVerify?${matriculation}`
                                    //path: `/identification?${matriculation}`
                                })
                            }
                        }else{

                            this.text_msg_error = 'Colaborador não encontrado!'
                            this.msg_error = true
                        }

                    }).catch((e) => {

                        this.loader = false

                        if (e.code === 'ECONNABORTED') {

                        }
                    });
                }
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

.pre_loader {
  position: absolute;
  top: calc(50% - 58px);
  left: calc(50% - 150px);
}

.btn_pre_loader {
  display: flex;
  align-items: center;
  padding: 35px 60px;
  font-size: 20px;
  background-color: #6237a0 !important;
  opacity: 1 !important;
  font-weight: 600;
}

.btn_pre_loader,
.btn_pre_loader:focus {
  outline: 0 !important;
  border: none !important;
}

.btn_pre_loader span {
  padding: 12px;
  margin-right: 15px;
}
.matricula {
    height: calc(100vh - 90px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.matricula-card {
    transition: 1s;
    min-width: 250px;
    width: 30%;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 3vw;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.matricula-title {
    font-size:25px;
    color: rgb(80, 79, 79);
    width: 100%;
    text-align: center;
    margin-bottom: 45px;
    font-weight: 400;
}

.msg_error {
    font-size:20px;
    color: red
}

.input-container {
    margin-bottom: 15px;
}

.matricula-form {
    margin-bottom: 15px;
}

.btn-container:not(:last-child) {
    margin-bottom: 15px;
}

.nav-link {
    padding: 0;
}

@media only screen and (min-width: 1400px) {
    .matricula-title {
        font-size: 30px;
    }

    .input-container {
        margin-bottom: 25px;
    }

    .matricula-form {
        margin-bottom: 25px;
    }

    .btn-container:not(:last-child) {
        margin-bottom: 25px;
    }
}

@media only screen and (max-width: 1200px) {
    .matricula-card {
        padding: 8vw;
        width: 50%;
    }
}
</style>

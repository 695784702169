<template>
	<input
		class="standard-input"
		:class="fieldStyle"
		:name="name"
		:placeholder="placeholder"
		autocomplete="off"
		:type="type"
		@focus="$emit('focus')"
		@blur="$emit('blur')"
	/>
</template>

<script>
export default {
	name: "Input",
	props: {
		name: { default: "inputarea" },
		placeholder: { default: "Campo de Texto" },
		type: { default: "text" },
		fieldStyle: "",
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.standard-input {
	width: 100%;
	color: #495057;
	border: 1px solid #ced4da;
	transition: 0.15s;
	padding: 16px 32px;
	box-sizing: border-box;
	border-radius: 100px;
	font-size: 16px;
	outline: 0;
}

.standard-input:focus {
	border: 1px solid #80bdff;
	box-shadow: 0 0 0 4px #bfdeff;
	z-index: 99 !important;
	position: relative;
}

::-webkit-input-placeholder {
	color: rgba(0, 0, 0, 0.4);
}

.square {
	border-radius: 5px;
}
</style>

<template>
	<div>
		<canvas :id="chartId" width="100%"></canvas>
	</div>
</template>
<script>
import Chart from "chart.js";

export default {
	name: "Pie",
	props: {
		chartLabels: {
			type: Array,
		},
		chartColors: {
			type: Array,
		},
		chartNumbers: {
			type: Array,
		},
		chartTitle: {
			default: "Título",
		},
		chartId: {
			default: "chart",
		},
	},
	methods: {
		createChart(chartId, chartData) {
			const ctx = document.getElementById(chartId);
			const myChart = new Chart(ctx, {
				type: chartData.type,
				data: chartData.data,
				options: chartData.options,
			});
		},
	},
	mounted() {
		this.createChart(this.chartId, {
			type: "pie",
			data: {
				labels: this.chartLabels,
				datasets: [
					{
						backgroundColor: this.chartColors,
						data: this.chartNumbers,
					},
				],
			},
			options: {
				title: {
					display: true,
					text: this.chartTitle,
					fontSize: 15,
				},
				legend: {
					display: false,
				},
				responsive: true,
				maintainAspectRatio: true,
			},
		});
	},
};
</script>

<template>
<div class="balance">
    <div class="row">
        <div class="col-md-4">
            <div class="list-people">
                <div class="card">
                    <div class="card-header">
                        Colaboradores
                    </div>
                    <div class="card-body">
                        <div v-if="false" class="search">
                            <input type="text" class="form-control" v-model="search_people" placeholder="Pesquisa">
                        </div>
                        <table class="table table-sm">
                            <tbody>
                                <tr v-for="(iten, index) in getItensPeople()" :key="index">
                                    <td>
                                        <a @click="select_people(iten)">
                                            <div class="info-people-list">
                                                <div class="image">
                                                    <img src="@/assets/perfil.png">
                                                </div>
                                                <div class="info-product">
                                                    <p><b>Código:</b> {{iten.people_code}}</p>
                                                    <p><b>Matrícula:</b> {{iten.people_matriculation}}</p>
                                                    <p><b>Nome:</b> {{iten.people_name}}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-observe-visibility="visibilityChanged">...</div>
                    </div>
                </div>
                <button @click="back" class="btn btn-danger btn_close">Fechar</button>
            </div>
        </div>
        <div class="col-md-8">
            <div class="info">
                <div class="info-people">
                    <div class="card">
                        <div class="title-info">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Matricula: {{list_people.people_matriculation}} </p>
                                    </div>
                                    <div class="col-md-8">
                                        <p>Nome: {{list_people.people_name}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4"><b>Unidade Atual</b></div>
                                <div class="col-md-4"><b>Área Atual</b></div>
                                <div class="col-md-4"><b>Setor Atual</b></div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">{{list_people.fantasy_name}}</div>
                                <div class="col-md-4">{{list_people.zone_name}}</div>
                                <div class="col-md-4">{{list_people.sector_name}}</div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-4"><b>Centro De Custo</b></div>
                                <div class="col-md-4"><b>Grupo Atual</b></div>
                                <div class="col-md-4"><b>Situação</b></div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">{{list_people.people_cost_center}}</div>
                                <div class="col-md-4">{{list_people.group_name}}</div>
                                <div class="col-md-4">{{list_people.people_status}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="balance-people">
                    <div class="card">
                        <div class="title-product">
                            <div class="card-header">
                                <p>Saldo</p>
                            </div>
                        </div>
                        <div class="card-body">
                            <div v-if="false" class="search">
                                <input type="text" class="form-control" v-model="search_product" placeholder="Pesquisa">
                            </div>
                            <table class="table table-sm">
                                <tbody>
                                    <tr v-for="(iten, index) in getItensProducts()" :key="index">
                                        <td>
                                            <a>
                                                <div class="info-product-list">
                                                    <div class="image">
                                                        <img src="@/assets/perfil.png">
                                                    </div>
                                                    <div class="info-product">
                                                        <p><b>Produto:</b> {{iten.product}}</p>
                                                        <p><b>Unidade:</b> {{iten.product_quantity}} {{iten.unit_measurements_name}}</p>
                                                        <p><b>Saldo:</b> {{iten.quantity}}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="loader == true" class="pre_loader">
            <button class="btn btn-primary btn_pre_loader" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Carregando ...
            </button>
        </div>
    </div>
</div>
</template>

<script>

import { baseApiUrl } from '../global';

import axios from 'axios'

export default {
    name: "TabsCard",
    data() {
        return {
            list_peoples: [],
            list_products: '',
            people_selected: '',
            search_people: '',
            search_product: '',
            list_people: {
                matriculation: '---',
                name: '---',
                sector_id: '---',
                cost_center: '---',
                group_id: '---',
                status: '---'
            },
            loader:false,
            page:1
        };
    },
    mounted() {

        this.loader = true

        this.listPeople()
    },
    methods: {
        visibilityChanged(isVisible){

            if(!isVisible) return;

            this.page++

            this.listPeople();

            console.log('chegou aqui')
        },
        /*-------------------- Lista os colaboradores da empresa no sistema local ---------------------------------*/
        listPeople() {

            const method = 'get'
            var rotas = 'list_all_people_local'

            const options = {
                headers: {
                    "Accept": "application/json"
                }
            }

            axios[method](`${baseApiUrl}/${rotas}?page=${this.page}`, {
                timeout: 20000
            }, options).then(res => {

                console.log(res.data)

                this.list_peoples.push(...res.data.list_all_people_local.data)

                this.loader = false

            }).catch((e) => {

                console.log(e)

                if (e.code === 'ECONNABORTED') {

                }
            });
        },
        /*-------------------- Lista os colaboradores da empresa no sistema local por pesquisa --------------------*/
        getItensPeople() {

            var listpeoples

            if (this.search_people) {

                listpeoples = this.list_peoples.filter(peoples => peoples.code.includes(this.search_people) || peoples.name.includes(this.search_people) || peoples.matriculation.includes(this.search_people));

            } else {

                listpeoples = this.list_peoples
            }

            return listpeoples
        },
        /*-------------------- Seleciona um colaborador da lista para exibir as informações -----------------------*/
        select_people(iten) {

            this.loader = true

            this.list_people = iten

            this.people_selected = iten.people_id_web

            this.list_product()
        },
        list_product() {

            const method = 'get'
            var rotas = 'list_product_balance'

            const options = {
                headers: {
                    "Accept": "application/json"
                }
            }

            var user_id = this.people_selected

            axios[method](`${baseApiUrl}/${rotas}/${user_id}`, {
                timeout: 20000
            }, options).then(res => {

                console.log(res.data)
                

                this.list_products = res.data.list_products

               
                this.loader = false

            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {

                }
            });
        },
        select_product() {
            this.$store.commit("select_product", "require(`@/assets/perfil.png")
        },
        getItensProducts() {

            var listproducts

            if (this.search_product) {

                listproducts = this.list_products.filter(products => products.product.includes(this.search_product) || products.quantity.includes(this.search_product));

            } else {

                listproducts = this.list_products
            }

            return listproducts
        },
        back() {
            this.$router.push({
                path: `/#2`,
            });
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

.pre_loader{
  position:absolute;
  top: calc(50% - 58px);
  left: calc(50% - 173px);
}

.btn_pre_loader{
  display: flex;
  align-items: center;
  padding: 40px 70px;
  font-size:25px;
  background-color: #6237A0 !important;
  opacity: 1 !important;
}

.btn_pre_loader, .btn_pre_loader:focus{
  outline: 0 !important;
  border:none !important;
}

.btn_pre_loader span{
  padding:12px;
  margin-right: 15px;
}

.balance {
    width: 100%;
}

.card-body {
    padding-top:0px;
    overflow-y: scroll;
}

.list-people .card {
    height: calc(100vh - 115px);
    margin-bottom: 10px;
}

.list-people .card-header {
    color: #fff;
    background-color: #7E5BD3;
    font-size: 20px;
    font-weight: 24px;
    font-weight: 400;
}

.btn_close{
    width: 100%;
    outline: none !important;
    box-shadow: none;
}

.btn_close:focus{
    outline: none !important;
    box-shadow: none;
}

.list-people a {
    cursor: pointer;
}

.list-people .info-people-list {
    display: flex;
    margin-top: 20px;
    justify-content: start;
    align-items: center;
}

.list-people .info-people-list .image img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 15px;
}

.list-people .info-people-list .info-product p {
    margin-bottom: 0px;
    text-align: left;
    font-size: 15px;
    line-height: 16px;
}

.info .info-people .title-info .card-header {
    color: #fff;
    background-color: #7E5BD3;
    font-size: 20px;
    font-weight: 24px;
    font-weight: 400;
}

.info .info-people .title-info .card-body {
    padding: 8px;
}

.info .info-people .title-info p {
    margin-bottom: 0px;
}

.info .balance-people .card {
    height: 53vh;
}

.info .balance-people .title-product .card-header {
    color: #fff;
    background-color: #7E5BD3;
    font-size: 20px;
    font-weight: 24px;
    font-weight: 400;
}

.info .balance-people .title-product p {
    margin-bottom: 0px;
}

.info .balance-people .info-product-list {
    display: flex;
    margin-top: 20px;
    justify-content: start;
    align-items: center;
}

.info .balance-people .info-product-list .image img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 15px;
}

.info .balance-people .info-product-list .info-product p {
    margin-bottom: 0px;
    text-align: left;
    font-size: 15px;
    line-height: 16px;
}

.info .balance-people .product {
    display: flex;
    margin-top: 20px;
    justify-content: start;
    align-items: center;
}

.info .balance-people .product .image img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 15px;
}

.info .balance-people .product .info-product p {
    margin-bottom: 0px;
    text-align: left;
    font-size: 15px;
    line-height: 16px;
}

/*
.card_1 {
    border: 1px solid #A9A9A9;
}

.search {
    margin-top: 10px;
    padding-right: 12px;
}

.container {
    padding-right: 0px;
}

.container .products {
    height: 70vh;
    overflow-y: scroll
}

.content {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    align-items: center;
}

.content .logo img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 5px;
}

.content .info p {
    margin-bottom: 0px;
    text-align: left;
    font-size: 15px;
    line-height: 16px;
}

.content .quantity p {
    padding: 0px 12px;
    color: #fff;
    background-color: green;
    border-radius: 10px;
    margin-right: 8px;
    margin-bottom: 0px;
    font-size: 17px;
    font-weight: 600;
}

.products::-webkit-scrollbar {
    width: 15px;
}

.products::-webkit-scrollbar-thumb {
    background-color: #A9A9A9;
    /* color of the scroll thumb */
/*border-radius: 20px;
    /* roundness of the scroll thumb */
/*border: 3px solid #fff;
    /* creates padding around scroll thumb */
/*}*/
</style>

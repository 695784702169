<template>
	<div>
		<div class="carousel-container">
			<div
				class="main-carousel transition-time"
				id="main-carousel"
				:carouselValue="navigateNumber"
			>
				<div class="slide-div">
					<div class="container-fluid container-2 ">
						<div class="content">
							<div @click="identificacao" class="card-container">
								<Card
									cardSize="smallCard"
									cardName="Identificação"
									iconName="id-card"
								/>
							</div>
							<div @click="retiradaProduct" class="card-container">
								<Card
									cardSize="smallCard"
									cardName="Retirada"
									iconName="delivery"
								/>
							</div>
							<div @click="redefinir" class="card-container">
								<Card
									cardSize="smallCard"
									iconName="reset"
									cardName="Redefinir"
								/>
							</div>
							<div @click="requisicao" class="card-container">
								<Card
									cardSize="smallCard"
									iconName="request"
									cardName="Requisição"
								/>
							</div>
						</div>
					</div>
				</div>

				<div v-if="false" class="slide-div">
					<div class="container-fluid container-2">
						<div class="content">
							<div v-if="false" class="card-container">
								<Card
									cardSize="smallCard"
									iconName="devices"
									cardName="Dispositivos"
								/>
							</div>
							<!--linkTo="/dispositivos"-->
							<div v-if="false" class="card-container">
								<Card
									cardSize="smallCard"
									iconName="fingerprint"
									cardName="Leitores"
								/>
							</div>
							<!--linkTo="/leitores"-->
							<div v-if="false" class="card-container">
								<Card
									cardSize="smallCard"
									iconName="download"
									cardName="Carga"
								/>
							</div>
							<!--linkTo="/carga""-->
							<div v-if="false" class="card-container">
								<Card
									cardSize="smallCard"
									iconName="export"
									cardName="Exportar"
								/>
							</div>
							<!--linkTo="/exportar"-->
							<div v-if="false" @click="sync" class="card-container">
								<Card
									cardSize="smallCard"
									iconName="sync"
									cardName="Sincronização"
								/>
							</div>
							<div v-if="false" @click="requisicao" class="card-container">
								<Card
									cardSize="smallCard"
									iconName="request"
									cardName="Requisição"
									
								/>
							</div>
							<div v-if="false" @click="redefinir" class="card-container">
								<Card
									cardSize="smallCard"
									iconName="reset"
									cardName="Redefinir"
								/>
							</div>
							<div v-if="false" @click="saldo" class="card-container">
								<Card
									cardSize="smallCard"
									iconName="clipboard"
									cardName="Consulta de Saldos"
								/>
							</div>
						</div>
					</div>
				</div>

				<!--<div class="slide-div">
					<div class="container-fluid container-3">
						<ChartsPage />
					</div>
				</div>-->
			</div>
			<div v-if="false" class="previous">
				<div class="prev-arrow" @click="navigatePrev"></div>
			</div>
			<div v-if="false" class="next">
				<div class="next-arrow" @click="navigateNext"></div>
			</div>
		</div>
		<div v-if="false" class="pagination-container">
			<div class="pagination">
				<div class="pag-item pag-item-selected"></div>
				<div class="pag-item"></div>
				<div class="pag-item"></div>
			</div>
		</div>
	</div>
</template>

<script>
import Card from "@/components/Card";
import ChartsPage from "@/components/ChartsPage";

export default {
	name: "Slider",
	data() {
		return {
			navigateNumber: 1,
		};
	},
	components: {
		Card,
		ChartsPage,
	},
	methods: {
		identificacao(){

			var userLogado = localStorage.getItem('userKey')
			
			var matriculation = 0

			if(userLogado){
				this.$router.push({
                	path: `/id`
            	})
			}else{
				this.$router.push({
                	path: `login?identificacao` 
            	})
			}
		},
		retiradaProduct(){

			var userLogado = localStorage.getItem('userKey')
			
			var matriculation = 0

			if(userLogado){
				this.$router.push({
                	path: `/matricula?retirada`
            	})
			}else{
				this.$router.push({
                	path: `login?retirada` 
            	})
			}
		},
		sync(){

			var userLogado = localStorage.getItem('userKey')
			
			var matriculation = 0

			if(userLogado){
				this.$router.push({
                	path: `/passwordVerifySinc`
            	})
			}else{
				this.$router.push({
                	path: `login?sync` 
            	})
			}
		},
		requisicao(){

			var userLogado = localStorage.getItem('userKey')
			
			var matriculation = 0

			if(userLogado){
				this.$router.push({
                	path: `/requisicao`
            	})
			}else{
				this.$router.push({
                	path: `login?requisicao` 
            	})
			}
		},
		redefinir(){

			var userLogado = localStorage.getItem('userKey')
			
			var matriculation = 0

			if(userLogado){
				this.$router.push({
                	path: `/redefinir`
            	})
			}else{
				this.$router.push({
                	path: `login?redefinir` 
            	})
			}
		},
		saldo(){

			var userLogado = localStorage.getItem('userKey')

			if(userLogado){
				this.$router.push({
                	path: `/saldo`
            	})
			}else{
				this.$router.push({
                	path: `login?saldo` 
            	})
			}
		},
		//Controla a navegação do SLIDER pelas setas laterais na tela
		navigateNext() {
			let carousel = document.getElementById("main-carousel");
			if (this.navigateNumber == 1) {
				carousel.style.transform = "translateX(-100vw)";
				this.navigateNumber = 2;
			} else if (this.navigateNumber == 2) {
				carousel.style.transform = "translateX(-200vw)";
				this.navigateNumber = 3;
			} else {
				carousel.style.transform = "translateX(0px)";
				this.navigateNumber = 1;
			}
			location.href = "#" + this.navigateNumber;
		},
		navigatePrev() {
			let carousel = document.getElementById("main-carousel");
			if (this.navigateNumber == 1) {
				carousel.style.transform = "translateX(-200vw)";
				this.navigateNumber = 3;
			} else if (this.navigateNumber == 2) {
				carousel.style.transform = "translateX(0px)";
				this.navigateNumber = 1;
			} else {
				carousel.style.transform = "translateX(-100vw)";
				this.navigateNumber = 2;
			}
			location.href = "#" + this.navigateNumber;
		},
	},

	//Muda a paginação em baixo
	watch: {
		navigateNumber: function changePagination() {
			let pagination = document.getElementsByClassName("pag-item");
			pagination.forEach(function(e) {
				e.classList.remove("pag-item-selected");
			});
			pagination[this.navigateNumber - 1].classList.add("pag-item-selected");
		},

		//
	},

	mounted() {
		let url = window.location.href;
		let carousel = document.getElementById("main-carousel");
		url = url.substring(url.indexOf("#") + 1, url.length);
		carousel.classList.remove("transition-time");
		if (url == "3") {
			carousel.style.transform = "translateX(50vw)";
			carousel.style.transform = "translateX(-200vw)";
			this.navigateNumber = 3;
		} else if (url == "2") {
			carousel.style.transform = "translateX(1px)";
			carousel.style.transform = "translateX(-100vw)";
			this.navigateNumber = 2;
		} else {
			carousel.style.transform = "translateX(1px)";
			carousel.style.transform = "translateX(0px)";
			this.navigateNumber = 1;
		}
		setTimeout(() => {
			carousel.classList.add("transition-time");
		}, 20);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-div {
	overflow: hidden;
}

.main-carousel {
	width: 300vw;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.transition-time {
	transition: 0.4s;
}

.container {
	padding-left: 10%;
	padding-right: 10%;
	width: 80%;
	height: calc(100vh - 90px);
	display: flex;
	align-items: center;
}

.container-1 .content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	height: calc(100vh - 90px);
	padding-left:7%;
	padding-right:7%;
}

.container-2 .content {
	display: grid;
	/*grid-template-rows: 1fr 1fr;*/
	grid-template-rows: 1fr;
	/*grid-template-columns: 2fr 2fr 2fr 2fr;*/
	grid-template-columns: 2fr 2fr;
	width: 100%;
	height: calc(100vh - 90px);
	padding-left:7%;
	padding-right:7%;
}

.container-2 .content .card-container {
	margin-bottom: 5px;
	margin-top: 0;
}

.container-3 {
	padding: 0;
	width: 100%;
	height: calc(100vh - 90px);
}

.nav-link{
	padding: 0;
}

@media only screen and (max-width: 1200px) {
	.container-1 .content {
		grid-template-rows: 1fr 1fr;
		grid-template-columns: 1fr;
	}

	.container-1 .card-container {
		margin: 1% 0;
	}

	.container-2 .content {
		grid-template-rows: 1fr 1fr 1fr 1fr;
		grid-template-columns: 1fr 1fr;
	}

	.container-2,
	.container-3 {
		overflow-y: scroll;
		display: flex;
		align-items: flex-start;
	}

	.card-container:nth-child(2n + 1) .small {
		width: 100% !important;
		margin-right: 5%;
		margin-left: 5%;
	}

	.card-container:nth-child(2n + 2) .small {
		width: 100% !important;
		margin-left: 5%;
		margin-right: 5%;
	}
}

@media only screen and (max-width: 500px) {
	.container-1 .content {
		grid-template-rows: 1fr 1fr;
		grid-template-columns: 1fr;
	}

	.container-1 .card-container {
		margin: 1% 0;
	}

	.container-2 .content {
		grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		grid-template-columns: 1fr;
		margin-top: 0px;
	}

	.container-2,
	.container-3 {
		overflow-y: scroll;
		align-items: flex-start;
	}
	.container-2 .content .card-container {
		margin-bottom: 1%;
		margin-top: 1%;
	}
}

.card-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 0;
}

.example-slide {
	align-items: center;
	background-color: #666;
	color: #999;
	display: flex;
	font-size: 1.5rem;
	justify-content: center;
	min-height: 10rem;
}

.previous,
.next {
	position: absolute;
	top: calc(50vh - 12px);
}

.previous {
	left: 5%;
}

.next {
	right: 5%;
}

.carousel-container {
	height: calc(100vh - 90px);
}

.pagination-container {
	position: absolute;
	bottom: 50px;
	width: 100vw;
	display: flex;
	justify-content: center;
}

.pagination {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.pag-item {
	margin-left: 4px;
	margin-right: 4px;
}

.pag-item {
	background-color: rgba(0, 0, 0, 0.3);
	height: 30px;
	width: 30px;
	mask: url(../assets/pag.svg);
}

.pag-item-selected {
	background-color: rgba(0, 0, 0, 0.6);
}

.prev-arrow,
.next-arrow {
	cursor: pointer;
	background-color: #888;
	mask: url(../assets/arrow.svg);
	height: 25px;
	width: 25px;
}

.prev-arrow:hover,
.next-arrow:hover {
	background-color: #555;
}

.prev-arrow {
	transform: rotate(180deg);
}

@media (min-aspect-ratio: 5/10) {
	@media (max-aspect-ratio: 6/10) {
		.carousel-container {
			height: calc(100vh - 150px);
		}

		.container {
			height: calc(100vh - 150px);
		}
	}
}
</style>

import Vue from "vue";

require('dotenv').config()

export const baseApiUrl = process.env.VUE_APP_BASEAPIURL
export const baseApiUrlAdmin = process.env.VUE_APP_BASEAPIURLADMIN
export const redirectUrl = process.env.VUE_APP_REDIRECTURL

export function showError(e) {

    if (e && e.response && e.response.data) {
        Vue.toasted.global.defaultError({ msg: e.response.data })

    } else if (typeof e === 'string') {
        Vue.toasted.global.defaultError({ msg: e })
    } else {
        Vue.toasted.global.defaultError()
    }
}

//export default {showError}
import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import VueCrontab from 'vue-crontab'


import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

/*import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)*/

import {ObserveVisibility} from "vue-observe-visibility";

Vue.directive('observe-visibility', ObserveVisibility)


import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import store from "./store/index";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import './assets/css/style.css'

import './config/msgs'

Vue.use(VueCrontab)

const options = {
    // You can set your default options here
};


Vue.use(Toast, options);


Vue.config.productionTip = false;

new Vue({
	store,
	router,
	render: h => h(App),
}).$mount("#app");

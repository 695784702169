<template>
<div>
    <NavBar />
    <div class="matricula">
        <div class="matricula-card">
            <h3 class="matricula-title">Autenticação biométrica</h3>
            <span><i class="fas fa-hand-point-up icon-finger"></i></span>
            <h3 v-if="msg_inicial == true" class="matricula-description">Posicione o dedo no leitor e click em verificar</h3>

            <h3 v-if="msg_error == true" class="msg_error">{{text_msg_error}}</h3>
            <h3 v-if="msg_action == true" class="msg_action">{{text_msg_action}}</h3>
            <br>
            <form class="matricula-form" action="javascript:void(0);" @submit="sendMatricula">
                <div class="btn-container">
                    <Button buttonText="Verificar" />
                </div>
            </form>
            <div class="btn-container">
                <Button buttonText="Cancelar" buttonColor="red" linkTo="/" />
            </div>
        </div>
    </div>
    <Footer />
</div>
</template>

<script>
import axios from '@/con'

import Button from "@/components/Button";
import Input from "@/components/Input";
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";

export default {
    name: "Matricula",
    data: function () {
        return {
            msg_error: false,
            text_msg_error: '',
            msg_inicial:true,
            try_autentication:0,
            msg_action:false,
            text_msg_action:''
        }
    },
    components: {
        Button,
        Input,
        NavBar,
        Footer
    },
    methods:{
        sendMatricula(e) {

            this.msg_error = false

            this.msg_action = true
            this.text_msg_action = "Verificando"

            this.msg_inicial = false

            let url = window.location.href;
            var matriculation = url.substring(url.indexOf("?") + 1, url.length);
       
            const method = 'get'
            var rotas = 'verify_biometric'

            const options = {
                headers: {
                    "Accept": "application/json"
                }
            }

            axios[method](`http://balcao_backend.com/${rotas}/${matriculation}`, {
                timeout: 5000
            }, options).then(res => {

                console.log(res.data)

                if(res.data.code == 2000){

                    this.try_autentication = 0

                    this.$router.push({
                        path: `/identification?${matriculation},Digital`
                    })
                }else if(res.data.code == 5000){

                    this.try_autentication += 1 

                    console.log(this.try_autentication)

                    if(this.try_autentication == 9){
                        this.$router.push({
                            path: `passwordVerify?${matriculation}`
                        })
                    }else{
                        this.verify1(matriculation)
                    }
                }
            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                }
            });    
        },
        verify1(matricula) {

            var matriculation = matricula
       
            const method = 'get'
            var rotas = 'verify_biometric1'

            const options = {
                headers: {
                    "Accept": "application/json"
                }
            }

            axios[method](`http://balcao_backend.com/${rotas}/${matriculation}`, {
                timeout: 5000
            }, options).then(res => {

                console.log(res.data)

                if(res.data.code == 2000){

                    this.try_autentication = 0

                    this.$router.push({
                        path: `/identification?${matriculation}`
                    })
                }else if(res.data.code == 5000){

                    this.try_autentication += 1

                    if(this.try_autentication == 9){
                        this.$router.push({
                            path: `passwordVerify?${matriculation}`
                        })
                    }else{
                        this.verify2(matriculation)
                    }
                }
            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                }
            });    
        },
        verify2(matricula) {

            var matriculation = matricula
       
            const method = 'get'
            var rotas = 'verify_biometric2'

            const options = {
                headers: {
                    "Accept": "application/json"
                }
            }

            axios[method](`http://balcao_backend.com/${rotas}/${matriculation}`, {
                timeout: 5000
            }, options).then(res => {

                console.log(res.data)

                if(res.data.code == 2000){

                    this.try_autentication = 0

                    this.$router.push({
                        path: `/identification?${matriculation}`
                    })
                }else if(res.data.code == 5000){

                    this.try_autentication += 1

                    if(this.try_autentication == 9){

                        this.try_autentication = 0

                        this.$router.push({
                            path: `passwordVerify?${matriculation}`
                        })
                    }else{
                        this.msg_action = false
                        this.msg_error = true
                        this.text_msg_error = "Autenticação falhou"
                    }
                }
            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                }
            });    
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.icon-finger{
    font-size:130px;
    padding-bottom: 25px;
    color:#6237A0;
}

.matricula {
    height: calc(100vh - 90px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.matricula-card {
    transition: 1s;
    min-width: 250px;
    width: 30%;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 3vw;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.matricula-title {
    color: #6237A0;
    width: 100%;
    text-align: center;
    margin-bottom: 45px;
    font-weight: 500;
}

.matricula-description{
    font-size:18px;
    color:#808080;
}

.msg_error {
    font-size:20px;
    color: red
}

.msg_action{
    font-size:20px;
    color:#808080;
}

.input-container {
    margin-bottom: 15px;
}

.matricula-form {
    margin-bottom: 15px;
}

.btn-container:not(:last-child) {
    margin-bottom: 15px;
}

.nav-link {
    padding: 0;
}

@media only screen and (min-width: 1400px) {
    .matricula-title {
        font-size: 30px;
    }

    .input-container {
        margin-bottom: 25px;
    }

    .matricula-form {
        margin-bottom: 25px;
    }

    .btn-container:not(:last-child) {
        margin-bottom: 25px;
    }
}

@media only screen and (max-width: 1200px) {
    .matricula-card {
        padding: 8vw;
        width: 50%;
    }
}
</style>

<template>
<div class="card_1">
    <nav>
        <div class="nav nav-tabs nav-justified" id="nav-tab" role="tablist">
            <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Permitidos</button>
            <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Requisitados</button>
            <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Devolução</button>
        </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            <div class="container">
                <div class="search">
                    <input type="text" class="form-control" placeholder="Pesquisa">
                </div>
                <div class="products">
                    <table class="table table-sm">

                        <body>
                            <tr>
                                <a>
                                    <td>
                                        <div class="content">
                                            <div class="logo">
                                                <img src="@/assets/perfil.png">
                                            </div>
                                            <div class="info">
                                                <p><b>Código:</b> 000001</p>
                                                <p><b>Produto:</b> Protetor auricular de silicone</p>
                                                <p><b>Unidade:</b> 1 peça</p>
                                            </div>
                                            <div class="quantity">
                                                <p>02</p>
                                            </div>
                                        </div>
                                    </td>
                                </a>
                            </tr>
                            <tr>
                                <td>
                                    <div class="content">
                                        <div class="logo">
                                            <img src="@/assets/perfil.png">
                                        </div>
                                        <div class="info">
                                            <p><b>Código:</b> 000001</p>
                                            <p><b>Produto:</b> Protetor auricular de silicone</p>
                                            <p><b>Unidade:</b> 1 peça</p>
                                        </div>
                                        <div class="quantity">
                                            <p>02</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="content">
                                        <div class="logo">
                                            <img src="@/assets/perfil.png">
                                        </div>
                                        <div class="info">
                                            <p><b>Código:</b> 000001</p>
                                            <p><b>Produto:</b> Protetor auricular de silicone</p>
                                            <p><b>Unidade:</b> 1 peça</p>
                                        </div>
                                        <div class="quantity">
                                            <p>02</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="content">
                                        <div class="logo">
                                            <img src="@/assets/perfil.png">
                                        </div>
                                        <div class="info">
                                            <p><b>Código:</b> 000001</p>
                                            <p><b>Produto:</b> Protetor auricular de silicone</p>
                                            <p><b>Unidade:</b> 1 peça</p>
                                        </div>
                                        <div class="quantity">
                                            <p>02</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="content">
                                        <div class="logo">
                                            <img src="@/assets/perfil.png">
                                        </div>
                                        <div class="info">
                                            <p><b>Código:</b> 000001</p>
                                            <p><b>Produto:</b> Protetor auricular de silicone</p>
                                            <p><b>Unidade:</b> 1 peça</p>
                                        </div>
                                        <div class="quantity">
                                            <p>02</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="content">
                                        <div class="logo">
                                            <img src="@/assets/perfil.png">
                                        </div>
                                        <div class="info">
                                            <p><b>Código:</b> 000001</p>
                                            <p><b>Produto:</b> Protetor auricular de silicone</p>
                                            <p><b>Unidade:</b> 1 peça</p>
                                        </div>
                                        <div class="quantity">
                                            <p>02</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="content">
                                        <div class="logo">
                                            <img src="@/assets/perfil.png">
                                        </div>
                                        <div class="info">
                                            <p><b>Código:</b> 000001</p>
                                            <p><b>Produto:</b> Protetor auricular de silicone</p>
                                            <p><b>Unidade:</b> 1 peça</p>
                                        </div>
                                        <div class="quantity">
                                            <p>02</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="content">
                                        <div class="logo">
                                            <img src="@/assets/perfil.png">
                                        </div>
                                        <div class="info">
                                            <p><b>Código:</b> 000001</p>
                                            <p><b>Produto:</b> Protetor auricular de silicone</p>
                                            <p><b>Unidade:</b> 1 peça</p>
                                        </div>
                                        <div class="quantity">
                                            <p>02</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="content">
                                        <div class="logo">
                                            <img src="@/assets/perfil.png">
                                        </div>
                                        <div class="info">
                                            <p><b>Código:</b> 000001</p>
                                            <p><b>Produto:</b> Protetor auricular de silicone</p>
                                            <p><b>Unidade:</b> 1 peça</p>
                                        </div>
                                        <div class="quantity">
                                            <p>02</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="content">
                                        <div class="logo">
                                            <img src="@/assets/perfil.png">
                                        </div>
                                        <div class="info">
                                            <p><b>Código:</b> 000001</p>
                                            <p><b>Produto:</b> Protetor auricular de silicone</p>
                                            <p><b>Unidade:</b> 1 peça</p>
                                        </div>
                                        <div class="quantity">
                                            <p>02</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </body>
                    </table>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">...</div>
        <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...</div>
    </div>
</div>
</template>

<script>
export default {
    name: "TabsCard",
    data() {
        return {
            
        };
    },
    methods: {
        select_product() {
            console.log(teste)
            this.$store.commit("select_product", "require(`@/assets/perfil.png")
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.card_1 {
    border: 1px solid #A9A9A9;
}

.search {
    margin-top: 10px;
    padding-right: 12px;
}

.container {
    padding-right: 0px;
}

.container .products {
    height: 70vh;
    overflow-y: scroll
}

.content {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    align-items: center;
}

.content .logo img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 5px;
}

.content .info p {
    margin-bottom: 0px;
    text-align: left;
    font-size: 15px;
    line-height: 16px;
}

.content .quantity p {
    padding: 0px 12px;
    color: #fff;
    background-color: green;
    border-radius: 10px;
    margin-right: 8px;
    margin-bottom: 0px;
    font-size: 17px;
    font-weight: 600;
}

.products::-webkit-scrollbar {
    width: 15px;
}

.products::-webkit-scrollbar-thumb {
    background-color: #A9A9A9;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid #fff;
    /* creates padding around scroll thumb */
}
</style>

<template>
	<div>
		<div class="nav">
			<router-link class="router-link" to="/">
				<div class="nv-title">
					<div class="icon-title"></div>
					Sempher
				</div>
			</router-link>
			<div class="nv-profile">
				<Dropdown />
			</div>
		</div>
	</div>
</template>

<script>
import Dropdown from "@/components/Dropdown";

export default {
	name: "NavBar",
	components: {
		Dropdown,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav {
	background-color: #6237A0;
	height: 45px;
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.nv-title,
.nv-profile {
	display: flex;
	align-items: center;
	height: 100%;
}

.nv-title {
	font-size: 18px;
	color: rgba(255, 255, 255, 0.95);
	padding: 0px 20px;
}

.nv-profile {
	justify-content: flex-end;
}

.icon-title {
	margin-right: 5px;
	background-color: rgba(255, 255, 255, 0.95);
}

.icon-title {
	opacity: 0.95;
	width: 19px;
	height: 19px;
	mask: url(../assets/atom.svg);
}

.nv-profile:hover {
	opacity: 1;
}
</style>

<template>
	<div>
		<router-link v-if="linkTo" class="router-link" :to="linkTo">
			<button
				class="standard-btn"
				:class="buttonColor + ' ' + btnStyle"
				type=""
				:style="iconStyle"
			>
				<div v-if="buttonIcon" class="return-arrow"></div>
				{{ buttonText }}
			</button>
		</router-link>

		<button
			v-if="!linkTo"
			class="standard-btn"
			:class="buttonColor + ' ' + btnStyle"
			type=""
			:style="iconStyle"
		>
			<div v-if="buttonIcon" class="return-arrow"></div>
			{{ buttonText }}
		</button>
	</div>
</template>

<script>
export default {
	name: "Button",
	props: {
		buttonText: {},
		buttonColor: {},
		buttonIcon: { default: "" },
		linkTo: { default: "" },
		btnStyle: { default: "" },
	},
	computed: {
		iconStyle() {
			if (this.buttonIcon.length > 0) {
				return {
					"--mask-name":
						"url(" + require("@/assets/" + this.buttonIcon + ".svg") + ")",
				};
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.standard-btn {
	transition: 0.1s;
	width: 100%;
	border: 0;
	background-color: #00aaff;
	color: #fff;
	padding: 16px 32px;
	border-radius: 100px;
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 1px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	text-justify: center;
	cursor: pointer;
	outline: none;
}

.standard-btn:hover {
	background-color: #0083ff;
}

.return-arrow {
	height: 16px;
	width: 16px;
	mask: var(--mask-name);
	background-color: #fff;
	margin-right: 10px;
}

.red {
	background-color: #e32636;
}

.red:hover {
	background-color: #b62937;
}

.g-blue {
	background-color: #0062ca;
}

.g-blue:hover {
	background-color: #0050a7;
}

.p-blue {
	background-color: #3c90c9;
}
.p-blue:hover {
	background-color: #3782b4;
}

.green{
	background-color: #228b22;
}

.green:hover{
	background-color: #247719;
}

.square {
	border-radius: 5px;
}
</style>

<template>
	<div>
		<NavBar />
		<div class="export-container">
			<div class="export-items">
				<div class="header-block">
					<div class="header-container">
						<h3 class="name">
							<label for="export-file"><div class="folder-icon"></div></label>

							<input type="file" id="export-file" />
						</h3>

						<div class="status">Selecione o arquivo de configuração</div>
					</div>
				</div>
				<div class="card-block">
					<div class="export-content">
						<div class="card-container">
							<Card
								cardName="A partir da ultima sincronização: "
								iconName="check"
							/>
						</div>
						<div class="card-container">
							<Card
								cardName="Todos os registros a partida de: "
								iconName="check"
							/>
						</div>
						<div class="card-container">
							<Card cardName="Cancelar exportação: " iconName="close" />
						</div>
					</div>
				</div>
				<div class="return-block">
					<div class="return-container">
						<Button
							buttonText="Voltar"
							linkTo="/#2"
							buttonIcon="return-arrow"
						/>
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import Card from "@/components/Card";
import Button from "@/components/Button";

export default {
	name: "Export",
	components: {
		NavBar,
		Footer,
		Card,
		Button,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.export-container {
	width: 100vw;
	height: calc(100vh - 90px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.export-content {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.card-block,
.return-block {
	display: flex;
	align-items: center;
	justify-content: center;
}

.return-container {
	width: 30%;
}

.card-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.export-items {
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-rows: 2fr 5fr 2fr;
}

.name {
	display: flex;
	justify-content: center;
	padding-top: 20px;
	padding-bottom: 10px;
}

.folder-icon {
	cursor: pointer;
	height: 30px;
	width: 30px;
	mask-size: 30px;
	background-color: #222e3c;
	mask: url(../assets/folder.svg);
}

#export-file {
	display: none;
}

.status {
	font-size: 20px;
}

.nav-link {
    padding: 0;
}

@media (min-aspect-ratio: 5/10) {
	@media (max-aspect-ratio: 6/10) {
		.export-container {
			height: calc(100vh - 150px);
		}
	}
}

@media (max-width: 1200px) {
	.export-content {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr 1fr;
	}

	.export-items {
		height: auto;
		margin: 0;
		grid-template-rows: 1fr 10fr 1fr;
	}

	.export-container {
		overflow-y: scroll;
		align-items: flex-start;
	}

	.card-container {
		margin: 5% 0;
	}

	.return-container {
		width: 60%;
	}
	@media (min-width: 700px) {
		@media (min-width: 700px) {
			.folder-icon {
				height: 45px;
				width: 45px;
				mask-size: 45px;
			}
			.status {
				font-size: 25px;
			}
		}
	}
}
</style>

<template>
	<div>
		<NavBar />
		<div class="charge-container">
			<div class="charge-items">
				<div class="header-block">
					<div class="header-container">
						<h3 class="name">
							Pedro Bonfilio
						</h3>

						<div class="status">
							Leitor {{ nome }} acionado, coloque o {{ leitura }} no leitor.
						</div>
					</div>
				</div>
				<div class="card-block">
					<div class="charge-content">
						<div class="card-container">
							<Card
								cardName="Aguardando leitura"
								iconName="check"
								:cardSpinner="true"
							/>
						</div>
					</div>
				</div>
				<div class="return-block">
					<div class="return-container">
						<Button
							buttonText="Voltar"
							linkTo="/leitores"
							buttonIcon="return-arrow"
						/>
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import Card from "@/components/Card";
import Button from "@/components/Button";

export default {
	name: "Scanning",
	data() {
		return {
			leitor: "",
			nome: "",
			leitura: "",
		};
	},
	components: {
		NavBar,
		Footer,
		Card,
		Button,
	},
	created() {
		let tipo = window.location.href;
		tipo = tipo.substring(tipo.indexOf("?") + 1, tipo.length);
		this.leitor = tipo;
		if (tipo == "biometrico") {
			this.nome = "biométrico";
			this.leitura = "dedo";
		} else {
			this.nome = "cartão";
			this.leitura = "cartão";
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.charge-container {
	width: 100vw;
	height: calc(100vh - 90px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.charge-content {
	width: 50%;
}

.card-block,
.return-block {
	display: flex;
	align-items: center;
	justify-content: center;
}

.return-container {
	width: 30%;
}

.card-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.charge-items {
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-rows: 2fr 5fr 2fr;
}

.name {
	display: flex;
	justify-content: center;
	padding-top: 20px;
	padding-bottom: 10px;
}

.folder-icon {
	cursor: pointer;
	height: 30px;
	width: 30px;
	mask-size: 30px;
	background-color: #222e3c;
	mask: url(../assets/folder.svg);
}

#charge-file {
	display: none;
}

.status {
	font-size: 20px;
}

.nav-link {
    padding: 0;
}

@media (min-aspect-ratio: 5/10) {
	@media (max-aspect-ratio: 6/10) {
		.charge-container {
			height: calc(100vh - 150px);
		}
	}
}

@media (max-width: 1200px) {
	.charge-content {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		width: 100%;
	}

	.charge-items {
		margin: 0;
		grid-template-rows: 1fr 4fr 1fr;
	}

	.charge-container {
		overflow-y: scroll;
		align-items: flex-start;
	}

	.card-container {
		margin: 5% 0;
	}

	.return-container {
		width: 60%;
	}
	@media (min-width: 700px) {
		.folder-icon {
			height: 45px;
			width: 45px;
			mask-size: 45px;
		}
		.status {
			font-size: 25px;
		}
	}
}
</style>

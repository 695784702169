<template>
	<div class="card-container">
		<div class="devices-card">
			<h2 class="title">{{ cardName }}</h2>
			<form
				class="matricula-form"
				action="javascript:void(0);"
				@submit="sendDevice"
			>
				<div class="row-container input-1">
					<Input name="tipo" placeholder="Tipo" />
				</div>
				<div class="row-container input-2">
					<div class="input-container">
						<Input name="descricao" placeholder="Descrição" />
					</div>
					<div class="input-container">
						<Input name="sequencia" placeholder="Sequência" />
					</div>
				</div>
				<div class="row-container input-3">
					<div class="input-container">
						<Input name="portaCom" placeholder="Porta de Comunicação" />
					</div>
					<div class="input-container">
						<Input name="config" placeholder="Configuração" />
					</div>
					<div class="input-container">
						<Select
							name="status"
							placeholder="Status"
							@clicked="selectStatus"
							:options="['Ativo', 'Inativo']"
						/>
					</div>
				</div>
				<div class="row-container button-container">
					<div class="button">
						<Button buttonText="Entrar" />
					</div>
					<div class="button">
						<Button
							buttonText="Voltar"
							buttonColor="red"
							linkTo="/dispositivos"
						/>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import Input from "@/components/Input";
import Button from "@/components/Button";
import Select from "@/components/Select";

export default {
	name: "DevicesCard",
	data() {
		return {
			cardName: "Título",
			cardURL: "",
			selectedStatus: "",
		};
	},
	components: {
		Input,
		Button,
		Select,
	},
	created() {
		let url = window.location.href;
		this.cardURL = url.substring(url.indexOf("?") + 1, url.length);

		if (this.cardURL == "cracha") {
			this.cardName = "Leitor de Crachá";
		} else if (this.cardURL == "biometrico") {
			this.cardName = "Leitor Biométrico";
		} else {
			this.cardName = this.cardURL;
		}
	},
	methods: {
		selectStatus(value) {
			this.selectedStatus = value;
		},
		sendDevice(e) {
			let userData = {};

			const form = e.target;
			const formData = new FormData(form);
			for (const [inputName, value] of formData) {
				userData[inputName] = value;
			}

			if (this.cardURL == "impressora") {
			} else if (this.cardURL == "cracha") {
			} else this.cardURL == "biometrico";
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-container {
	height: calc(100vh - 90px);
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

.devices-card {
	width: 60%;
	padding: 30px;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.title {
	font-weight: 400;
	padding-bottom: 30px;
	text-transform: capitalize;
}

.input-2,
.input-3 {
	display: grid;
}

.input-2 {
	grid-template-columns: 2fr 1fr;
}

.input-3 {
	grid-template-columns: 1fr 1fr 1fr;
}

.row-container {
	margin-bottom: 15px;
}

.input-container:not(:last-child) {
	margin-right: 15px;
}

.button-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.button:first-child {
	margin-right: 7px;
}
.button:last-child {
	margin-left: 7px;
}
</style>

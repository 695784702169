<template>
	<div>
		<NavBar />
		<div class="scanners-container">
			<div class="scanners-items">
				<div class="header-block">
					<div class="header-container">
						<h3 class="name">Pedro Bonfilio</h3>
						<div class="status">Selecione o tipo de leitor desejado</div>
					</div>
				</div>
				<div class="card-block">
					<div class="scanners-content">
						<div class="card-container">
							<Card
								cardName="Leitor Biométrico"
								iconName="fingerprint-scanner"
								linkTo="/lendo?biometrico"
							/>
						</div>
						<div class="card-container">
							<Card
								cardName="Leitor de Cartão"
								iconName="card"
								linkTo="/lendo?cartao"
							/>
						</div>
					</div>
				</div>
				<div class="return-block">
					<div class="return-container">
						<Button
							buttonText="Voltar"
							linkTo="/#2"
							buttonIcon="return-arrow"
						/>
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import Card from "@/components/Card";
import Button from "@/components/Button";

export default {
	name: "scanners",
	components: {
		NavBar,
		Footer,
		Card,
		Button,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.scanners-container {
	width: 100vw;
	height: calc(100vh - 90px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.scanners-content {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.card-block,
.return-block {
	display: flex;
	align-items: center;
	justify-content: center;
}

.return-container {
	width: 30%;
}

.card-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.scanners-items {
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-rows: 2fr 5fr 2fr;
}

.name {
	font-size: 22px;
	padding-top: 20px;
	padding-bottom: 10px;
}

.status {
	font-size: 20px;
}

.nav-link {
    padding: 0;
}

@media (min-aspect-ratio: 5/10) {
	@media (max-aspect-ratio: 6/10) {
		.scanners-container {
			height: calc(100vh - 150px);
		}
	}
}

@media (max-width: 1200px) {
	.scanners-content {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;
	}

	.scanners-items {
		height: auto;
		margin: 0;
		grid-template-rows: 1fr 5fr 1fr;
	}

	.scanners-container {
		overflow-y: scroll;
		align-items: flex-start;
	}

	.card-container {
		margin: 5% 0;
	}

	.return-container {
		width: 60%;
	}
	@media (min-width: 700px) {
		.name {
			font-size: 27px;
		}
		.status {
			font-size: 25px;
		}
	}
}
</style>

<template>
<div>
    <NavBar />
    <div class="id-container">
        <div class="id-items">
            <div class="header-block">
                <div v-if="false" class="header-container">
                    <h3 class="name">Pedro Bonfilio</h3>
                    <div class="status">{{counter}}</div>
                </div>
            </div>
            <div class="card-block">
                <div class="id-content">
                    <span @click="sinc_download" style="width:100%">
                        <div class="card-container">
                            <Card cardName="Download de Dados" iconName="cloud-download" iconDescription="Duração: 5 segundos" />
                        </div>
                    </span>
                    <span @click="sinc_upload" style="width:100%">
                        <div class="card-container">
                            <Card cardName="Upload de dados" iconName="cloud-upload" iconDescription="Duração: 1 segundo" />
                        </div>
                    </span>
                    <div class="card-container">
                        <Card cardName="Sincronização de Status" iconName="cloud" iconDescription="Duração: 0 segundos" />
                    </div>
                </div>
            </div>
            <div class="return-block">
                <div class="return-container">
                    <Button buttonText="Voltar" linkTo="/#2" buttonIcon="return-arrow" />
                </div>
            </div>
        </div>
    </div>
    <Footer />
</div>
</template>

<script>

import { baseApiUrl } from '../global';

import axios from '@/con'

import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import Card from "@/components/Card";
import Button from "@/components/Button";

export default {
    name: "Sync",
    data: function () {
        return {
            list_delivery_regra: '',
            list_delivery_request:'',
            counter: 0
        }
    },
    components: {
        NavBar,
        Footer,
        Card,
        Button,
    },
    created () {
        let result = this.$crontab.addJob({
            name: 'download',
            interval: {seconds: '0', minutes: '/1'},
            //job: this.sinc_download
        })

        let result_upload = this.$crontab.addJob({
            name: 'upload',
            interval: {seconds: '30', minutes: '/1'},
            //job: this.sinc_upload
        })
    },
    methods: {
        countUp () {
            this.counter += 1
        },
        sinc_download() {

            const method = 'get'
            var rotas = 'peoples-list-id'

            const options = {
                headers: {
                    "Accept": "application/json"
                }
            }
            
            axios[method](`${baseApiUrl}/${rotas}`, options).then(res => {

                console.log(res.data)

                this.$toasted.global.defaultSuccess({msg: ''});

            }).catch((e) => {

                console.log(e)
            });
        },
        sinc_upload() {

            const method = 'post'
            var rotas = 'list_delivery'

            const options = {
                headers: {
                    "Accept": "application/json"
                }
            }

            var field = {
                data: '01-10-2021'
            }

            var vm = this;

            axios[method](`${baseApiUrl}/${rotas}`, field, {
                timeout: 5000
            }, options).then(res => {

                this.list_delivery_regra   = res.data.delivery_regra
                this.list_delivery_request = res.data.delivery_request

                vm.sinc_upload_delivery_web()

            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {

                }
            });

        },
        sinc_upload_delivery_web() {

            const method = 'post'
            var rotas = 'register-delivery-regra'

            const options = {
                headers: {
                    "Accept": "application/json"
                }
            }

            var field = {
                'list_delivery_regra': this.list_delivery_regra,
                'list_delivery_request': this.list_delivery_request
            }

            axios[method](`${baseApiUrl}/${rotas}`, field, {
                timeout: 5000
            }, options).then(res => {

                this.$toasted.global.defaultSuccess({msg: ''});

            }).catch((e) => {

                console.log(e)

                    if (e.code === 'ECONNABORTED') {

                    }
            });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.id-container {
    width: 100vw;
    height: calc(100vh - 90px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.id-content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.card-block,
.return-block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.return-container {
    width: 30%;
}

.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.id-items {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 2fr 5fr 2fr;
}

.name {
    font-size: 22px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.status {
    font-size: 20px;
}

.nav-link {
    padding: 0;
}

@media (min-aspect-ratio: 5/10) {
    @media (max-aspect-ratio: 6/10) {
        .id-container {
            height: calc(100vh - 150px);
        }
    }
}

@media (max-width: 1200px) {
    .id-content {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }

    .id-items {
        height: auto;
        margin: 0;
        grid-template-rows: 1fr 10fr 1fr;
    }

    .id-container {
        overflow-y: scroll;
        align-items: flex-start;
    }

    .card-container {
        margin: 5% 0;
    }

    .return-container {
        width: 60%;
    }

    @media (min-width: 700px) {
        .name {
            font-size: 27px;
        }

        .status {
            font-size: 25px;
        }
    }
}
</style>

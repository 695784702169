import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/Main.vue";
import Id from "../views/Id.vue";
import Login from "../views/Login.vue";
import Matricula from "../views/Matricula.vue";
import Redirect from "../views/Redirect.vue";
import Sync from "../views/Sync.vue";
import Devices from "../views/Devices.vue";
import Scanners from "../views/Scanners.vue";
import Charge from "../views/Charge.vue";
import Export from "../views/Export.vue";
import DevicePage from "../views/DevicePage.vue";
import Retirada from "../views/Retirada.vue";
import Scanning from "../views/Scanning.vue";
import Request from "../views/Request.vue";
import Reset from "../views/Reset.vue";
import Balance from "../views/Balance.vue";
import Password from "../views/Password.vue";
import PasswordVerify from "../views/Password_verify.vue";
import PasswordVerifySinc from "../views/Password_verify_sinc.vue"
import CardRfid from "../views/Card_rfid";
import Identification from "../views/Identification";
import Biometria from "../views/Biometria";
import BiometriaResult from "../views/BiometriaResult";
import BiometricVerify from "../views/Biometria_verify";
import Perfil from "../views/Perfil";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Main,
	},
	{
		path: "/id",
		name: "Id",
		component: Id,
	},
	{
		path: "/login",
		name: "Login",
		component: Login,
	},
	{
		path: "/perfil",
		name: "Perfil",
		component: Perfil,
	},
	{
		path: "/matricula",
		name: "Matricula",
		component: Matricula
	},
	{
		path: "/redirect",
		name: "Redirect",
		component: Redirect,
	},
	{
		path: "/sync",
		name: "Sync",
		component: Sync,
	},
	{
		path: "/dispositivos",
		name: "Devices",
		component: Devices,
	},
	{
		path: "/leitores",
		name: "Scanners",
		component: Scanners,
	},
	{
		path: "/carga",
		name: "Charge",
		component: Charge,
	},
	{
		path: "/exportar",
		name: "Export",
		component: Export,
	},
	{
		path: "/pagDispositivo",
		name: "DevicePage",
		component: DevicePage,
	},
	{
		path: "/retirada",
		name: "Retirada",
		component: Retirada,
	},
	{
		path: "/lendo",
		name: "Scanning",
		component: Scanning,
	},
	{
		path: "/requisicao",
		name: "Request",
		component: Request,
	},
	{
		path: "/redefinir",
		name: "Reset",
		component: Reset,
	},
	{
		path: "/saldo",
		name: "Balance",
		component: Balance,
	},
	{
		path: "/password",
		name: "Password",
		component: Password,
	},
	{
		path: "/cardrfid",
		name: "CardRfid",
		component: CardRfid,
	},
	{
		path: "/identification",
		name: "Identification",
		component: Identification,
	},
	{
		path: "/biometria",
		name: "Biometria",
		component: Biometria,
	},
	{
		path: "/biometriaResult",
		name: "BiometriaResult",
		component: BiometriaResult,
	},
	{
		path: "/biometricVerify",
		name: "BiometricVerify",
		component: BiometricVerify,
	},
	{
		path: "/passwordVerify",
		name: "PasswordVerify",
		component: PasswordVerify,
	},
	{
		path: "/passwordVerifySinc",
		name: "PasswordVerifySinc",
		component: PasswordVerifySinc,
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;

<template>
  <div>
    <NavBar />
    <div class="withdraw">
      <div v-if="false" class="left-menu">
        <EpiMenu />
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-4">
            <div class="card">
              <div class="card-header">
                <p>Produtos</p>
              </div>
              <div class="card-body">
                <div class="search">
                  <input type="text" class="form-control input-search" placeholder="Pesquisa" v-model="searchProduct"/>
                  <button class="btn-search" @click="productSearch"><i class="fas fa-search"></i></button>
                </div>
                <div class="products-list">
                  <table class="table table-sm">
                    <tbody>
                      <tr v-for="(iten, index) in getitens()" :key="index">
                        <td>
                          <a @click="select_product(iten)">
                            <div class="content-product">
                              <div class="logo">
                                <img src="@/assets/perfil.png" />
                              </div>
                              <div class="info">
                                <p><b>Código:</b> {{ iten.code }}</p>
                                <p><b>Produto:</b> {{ iten.name }}</p>
                                <p><b>Ca:</b> {{ iten.ca }}</p>
                              </div>
                              <div class="quantity_list_green"
                                :class="{
                                  quantity_list_red:
                                    iten.users_quantitys_periods_quantity ==
                                    '0',
                                }"
                              >
                                <p>
                                  {{ iten.users_quantitys_periods_quantity }}
                                </p>
                              </div>
                            </div>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-if="loader1 == true" class="pre_loader">
                      <button class="btn btn-primary btn_pre_loader" type="button" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Carregando ...
                      </button>
                  </div>
                  <div v-observe-visibility="visibilityChanged_product">...</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <div class="card-header">
                <p>Requisição</p>
              </div>
              <div class="card-body card-body-request">
                <label class="label-title">Produto</label>
                <div class="content-people-selected">
                  <p v-if="!product_selected">Selecione um produto!</p>
                  <div v-if="product_selected" class="logo">
                    <img src="@/assets/perfil.png" />
                  </div>
                  <div v-if="product_selected" class="info">
                    <p><b>Código: </b>{{ request_product.code }}</p>
                    <p><b>Nome: </b>{{ request_product.name }}</p>
                    <p><b>Ca: </b>{{ request_product.ca }}</p>
                    <p>
                      <b>Unidade: </b>{{ request_product.quantity }}
                      {{ request_product.unit_measurements_name }}
                    </p>
                  </div>
                </div>
                <label class="label-title">Colaborador</label>
                <div class="content-product-selected">
                  <p v-if="!collaborator_selected">Selecione um colaborador!</p>
                  <div v-if="collaborator_selected" class="logo">
                    <img src="@/assets/perfil.png" />
                  </div>
                  <div v-if="collaborator_selected" class="info">
                    <p><b>Código: </b>{{request_collaborator.code}}</p>
                    <p><b>Matrícula:</b>{{request_collaborator.matriculation}}</p>
                    <p><b>Nome: </b>{{request_collaborator.name}}</p>
                  </div>
                </div>
                <div class="justification">
                  <label for="justification">Justificativa</label>
                  <select class="form-select" aria-label="Justification" id="justification" v-model="justification">
                    <option v-for="(iten, index) in justifications" :key="index" :value="iten.name">{{iten.name}}</option>
                  </select>
                </div>
                <div class="quantity">
                  <label for="justification">Quantidade</label>
                  <input type="text" class="form-control" v-model="quantity" />
                </div>
                <div class="control">
                  <label for="justification">Efeito no saldo</label>
                  <select class="form-select" aria-label="Default select example" v-model="balance">
                    <option selected value="Contabilizar">Contabilizar</option>
                    <option value="Contabilizar">Não Contabilizar</option>
                  </select>
                </div>
                <div class="btn-save">
                  <div class="row">
                    <div class="col-md-6">
                      <button @click="add" class="btn btn-primary" type="button">Salvar</button>
                    </div>
                    <div class="col-md-6">
                      <button @click="back" class="btn btn-danger" type="button">Cancelar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <div class="card-header">
                <p>Colaboradores</p>
              </div>
              <div class="card-body">
                <div class="search">
                  <input type="text" class="form-control input-search" placeholder="Pesquisa" v-model="searchCollaborator"/>
                  <button class="btn-search" @click="collaboratorSearch"><i class="fas fa-search"></i></button>
                </div>
                <div class="products-list">
                  <table class="table table-sm">
                    <tbody>
                      <tr v-for="(iten, index) in collaborators" :key="index">
                        <td>
                          <a @click="select_collaborator(iten, 'list-collaborators')">
                            <div class="content-product">
                              <div class="logo">
                                <img src="@/assets/perfil.png" />
                              </div>
                              <div class="info">
                                <p><b>Código:</b>{{ iten.code }}</p>
                                <p><b>Matrícula:</b>{{ iten.matriculation }}</p>
                                <p><b>Nome:</b>{{ iten.name }}</p>
                              </div>
                            </div>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-if="loader3 == true" class="pre_loader">
                      <button class="btn btn-primary btn_pre_loader" type="button" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Carregando ...
                      </button>
                  </div>
                  <div v-observe-visibility="visibilityChanged_collaborator">...</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loader2 == true" class="pre_loader">
        <button class="btn btn-primary btn_pre_loader" type="button" disabled>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Carregando ...
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { baseApiUrl, baseApiUrlAdmin } from "../global";

import axios from '@/con';

import NavBar from "@/components/NavBar";
import TabsCard from "@/components/TabsCard";
import EpiMenu from "@/components/EpiMenu";
import Select from "@/components/Select";
import Input from "@/components/Input";
import Button from "@/components/Button";

export default {
  name: "Redirect",
  data() {
    return {
      products:[],
      collaborators: [],
      justifications:[],
      justification:1,
      balance: "1",
      collaborator_selected: false,
      request_product: {},
      request_collaborator: {},
      quantity: "",
      balance:'Contabilizar',
      searchProduct: "",
      searchCollaborator:"",
      category: "",
      loader1:false,
      loader2:false,
      loader3:false,
      product_selected: false,
      
      
      
      
      equipamento: "",
      loader: false,
      pageCollaborator:1,
      pageProduct:1,
      searchProductChange:0
    };
  },
  components: {
    NavBar,
    TabsCard,
    EpiMenu,
    Select,
    Input,
    Button,
  },
  created() {
    this.loader1 = true;
    this.loader3 = true;

    //this.equipamentos();
    
    this.listProducts();
    this.listCollaborators();
    this.listJustification();
  },
  watch: {
    // whenever question changes, this function will run
    listcategory: function (newCategory, oldCategory) {},
  },
  computed: {
    listcategory() {
      this.category = this.$store.state.category;

      return this.category;
      //console.log(this.category)
    },
  },
  methods: {
    visibilityChanged_collaborator(isVisible){

      if(!isVisible) return;

      this.pageCollaborator++

      this.listCollaborators()

      //console.log('chegou aqui')
    },
    visibilityChanged_product(isVisible){

      if(!isVisible) return;

      this.pageProduct++

      this.listProducts();
    },
    productSearch(){

      this.products = []

      this.pageProduct = 1

      this.listProducts()
    },
    listProducts() {

      this.loader1 = true

      var user = JSON.parse(localStorage.getItem('userKey'))

      const method = "post";
      var rotas = "product_list_all_dependency_paginate";

      const options = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`
        },
      };

      let params = {
        search: this.searchProduct
      }

      //axios[method](`${baseApiUrl}/${rotas}?page=0&size=5`,options,{
      axios[method](`${baseApiUrlAdmin}/${rotas}?${this.pageProduct}&size=5`,params, options,{
          timeout: 5000,
      }).then((res) => {

        console.log(res.data);

        this.loader1 = false

        if(this.searchProduct.length > 0){

          this.products = []

          this.products.push(...res.data.listAllProducts.data);
        }else{

          this.products.push(...res.data.listAllProducts.data);
        }

        

      }).catch((e) => {

          this.loader1 = false

          if (e.code === "ECONNABORTED") {
          }
      });
    },
    collaboratorSearch(){

      this.collaborators = []

      this.pageCollaborator = 1

      this.listCollaborators()
    },
    listCollaborators() {

      console.log('chegou aqui 2')

      this.loader3 = true

      var user = JSON.parse(localStorage.getItem('userKey'))

      const method = "post";
      var rotas = "collaborator_list_all_dependency_paginate";

      const options = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`
        },
      };

      let params = {
        search: this.searchCollaborator
      }

      //axios[method](`${baseApiUrlAdmin}/${rotas}?page=0&size=5`,options,{
      axios[method](`${baseApiUrlAdmin}/${rotas}?page=${this.pageCollaborator}&size=5`,params, options,{
          timeout: 5000,
      }).then((res) => {
          
          console.log(res.data);

          this.loader3 = false

          this.collaborators.push(...res.data.listAllCollaborators.data)

          this.loader = false;

      }).catch((e) => {

        this.loader3 = false

          if (e.code === "ECONNABORTED") {
          }
      });
    },
    listJustification(){

      var user = JSON.parse(localStorage.getItem('userKey'))

      const method = "post";
      var rotas = "justification_list_all";

      const options = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`
        },
      };

      let params = {
        search:''
      }

      axios[method](`${baseApiUrlAdmin}/${rotas}`,params, options,{
          timeout: 5000,
      }).then((res) => {

          console.log(res.data);

          this.justifications = res.data.listAlljustification

          this.justification = this.justifications[0].name

      }).catch((e) => {
          if (e.code === "ECONNABORTED") {
          }
      });
    },
    select_collaborator(collaborator) {

      console.log(collaborator)

      this.request_collaborator = collaborator;

      this.collaborator_selected = true;

      //console.log(this.request_product)

      /*this.name_action_card = name_action

            if (name_action == 'list') {
                this.btn_name = "Adicionar"
                document.getElementById('btn_item').style.backgroundColor = '#0d6efd'
                document.getElementById('btn_item').style.borderColor = '#0d6efd'

            } else if (name_action == 'basket') {
                this.btn_name = "Remover"
                document.getElementById('btn_item').style.backgroundColor = 'red'
                document.getElementById('btn_item').style.borderColor = 'red'
            }

            this.product = image

            console.log(image)

            if (image.product_id == 1) {
                this.caminho = 'perfil.png'
            }
            if (image.product_id == 2) {
                this.caminho = 'protetor-auricular.jpeg'
            }*/
    },
    select_product(product) {

      this.request_product = product;

      this.product_selected = true;

      //console.log(this.request_product)

      /*this.name_action_card = name_action

            if (name_action == 'list') {
                this.btn_name = "Adicionar"
                document.getElementById('btn_item').style.backgroundColor = '#0d6efd'
                document.getElementById('btn_item').style.borderColor = '#0d6efd'

            } else if (name_action == 'basket') {
                this.btn_name = "Remover"
                document.getElementById('btn_item').style.backgroundColor = 'red'
                document.getElementById('btn_item').style.borderColor = 'red'
            }

            this.product = image

            console.log(image)

            if (image.product_id == 1) {
                this.caminho = 'perfil.png'
            }
            if (image.product_id == 2) {
                this.caminho = 'protetor-auricular.jpeg'
            }*/
    },
    verify_request(){

      var user = JSON.parse(localStorage.getItem('userKey'))

      const method= "post"
      var rotas = "delivery_request_single";

      var data = {
        //colaborator_id: this.request_collaborators.people_id,
        //code_product:this.request_product.code
        id:10
      };

      const options = {
        headers: {
          "Accept": "application/json",
          Authorization: `Bearer ${user.token}`
        },
      };

      axios[method](`${baseApiUrl}/${rotas}`,data,options,
      {
        timeout: 5000,
      })
      .then((res) => {

        console.log(res.data)

        this.add()

          /*if(res.data.code == 2000){
            this.add()
          }else{
            this.$toasted.global.defaultError({ msg: "Requisição já existe!" });
          }*/

      }).catch((e) => {
          if (e.code === "ECONNABORTED") {
          }
      });
    },
    add() {

      this.loader2 = true

      let user = JSON.parse(localStorage.getItem('userKey'))

      if ((Object.keys(this.request_product).length === 0) && (this.request_product.constructor === Object)) {
        this.$toasted.global.defaultError({ msg: "Selecione um produto!" });
        return;
      }

      if ((Object.keys(this.request_collaborator).length === 0) && (this.request_collaborator.constructor === Object)) {
        this.$toasted.global.defaultError({ msg: "Selecione um colaborador!" });
        return;
      }

      if (this.quantity === "") {
        this.$toasted.global.defaultError({
          msg: "O campo quantidade é obrigatório!",
        });
        return;
      }

      /*var data = {
        colaborator_id: this.request_collaborators.people_id,

        id_product: this.request_product.id,
        code_product: this.request_product.code,
        description_product: this.request_product.product,
        quantity_delivery_product: this.quantity,
        quantity_product: this.request_product.quantity,
        unit_measurement: this.request_product.unit_measurements_name,
        ca_product: this.request_product.ca,
        justification: this.justification,
        effect_balance: this.balance,

        ponto_entrega: 'Online',
        equipamento: 'Balcão Online',
        matricula_operador: operator.matricula,
        operador: operator.user
      };*/

      const method = "post";
      var rotas = "deliveryRequest";

      const options = {
          headers: {
              "Accept": "application/json",
              Authorization: `Bearer ${user.token}`
          }
      }

      let company = 'Technip'

      function formatarNumero(numero) {
    return numero < 10 ? `0${numero}` : numero;
}

      function obterDataFormatada() {
    const dataAtual = new Date();
    
    const dia = formatarNumero(dataAtual.getDate());
    const mes = formatarNumero(dataAtual.getMonth() + 1); // Os meses são baseados em zero
    const ano = dataAtual.getFullYear();
    
    return `${ano}-${mes}-${dia}`;
}

      function obterHoraFormatada() {
    const dataAtual = new Date();
    
    const horas = formatarNumero(dataAtual.getHours());
    const minutos = formatarNumero(dataAtual.getMinutes());
    const segundos = formatarNumero(dataAtual.getSeconds());
    
    return `${horas}:${minutos}:${segundos}`;
}


      let params = {
        company: company,
        company_unit:this.request_collaborator.companyUnitsName,
        sector:this.request_collaborator.sectorName,
        point_delivery: user.local,
        equipament_model: 'Online',
        collaborator:this.request_collaborator.name,
        matriculation:this.request_collaborator.matriculation,
        function: this.request_collaborator.roleName,
        code_product: this.request_product.code,
        product: this.request_product.name,
        ca: this.request_product.ca,
        quantity: this.quantity,
        identification: 'Matrícula',
        autentication: 'Senha',
        //role - verficar o que é esta regra e de onde vem a informação
        date_delivery: obterDataFormatada(),
        hour_delivery: obterHoraFormatada(),
        operator: user.user,
        type_delivery:'Requisição',
        gender:this.request_collaborator.gender,
        category: this.request_product.categorieName,
        ghe: this.request_collaborator.groupName,
        size: this.request_product.sizeName,
        justification:this.justification



        /*id_product: this.request_product.id,            
        id_web_product: this.request_product.id_web,
        code_product: this.request_product.code,
        name_product: this.request_product.name,
        ca_product: this.request_product.ca,
        unit_measurement_product: this.request_product.unitMeasurement.name,
        quantity_request_product: this.quantity,
        justification: this.justification,
        effect_balance: this.balance,
        id_collaborator: this.request_collaborator.id,
        id_web_collaborator: this.request_collaborator.id_web,
        code_collaborator: this.request_collaborator.code,
        firstName_collaborator:this.request_collaborator.firstName,
        lastName_collaborator:this.request_collaborator.lastName,
        matriculation_collaborator:this.request_collaborator.matriculation,
        tag_name_collaborator: this.request_collaborator.tag_name,
        zone_collaborator: this.request_collaborator.zone.name,
        sector_collaborator:this.request_collaborator.sector.name*/



        /*ponto_entrega: 'Online',
        equipamento: 'Balcão Online',
        local_entrega:'local',
        n_serie:'vdx145698'*/
      }


      axios[method](`${baseApiUrl}/${rotas}`, params, options,
      {
        timeout: 5000,
      }).then((res) => {
       // console.log(res.data);

        this.loader2 = false

        if(res.status === 200){

          this.request_product = "";
          this.request_collaborator = "";
          this.quantity = "";
          this.product_selected = false;
          this.collaborator_selected = false;

          this.$toasted.global.defaultSuccess({ msg: "Operação realizada com sucesso!" });
        }

        /*
        this.justification = "1";
        this.balance = "1";*/

      }).catch((e) => {

          this.loader2 = false

          if (e.code === "ECONNABORTED") {
          }
      });
},






















    


    getitens() {
      var listproducts;

      if (this.search && this.category && this.category != "All") {
        listproducts = this.products.filter(
          (products) =>
            products.category.includes(this.category) &&
            products.product.includes(this.search)
        );
      } else if (this.search) {
        listproducts = this.products.filter((products) =>
          products.product.includes(this.search)
        );
      } else if (this.category && this.category != "All") {
        console.log("pesquisa");
        listproducts = this.products.filter((products) =>
          products.category.includes(this.category)
        );
      } else {
        listproducts = this.products;
      }

      return listproducts;
    },
    equipamentos() {
      const method = "get";

      var rotas = "list_equipament";

      const options = {
        headers: {
          Accept: "application/json",
        },
      };

      axios[method](
        `${baseApiUrl}/${rotas}`,
        {
          timeout: 5000,
        },
        options
      )
        .then((res) => {
          console.log(res.data);

          this.equipamento = res.data.list_equipament;
        })
        .catch((e) => {
          if (e.code === "ECONNABORTED") {
          }
        });
    },
    back() {
      this.$router.push({
        path: `/`,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.pre_loader {
  position: absolute;
  top: calc(50% - 58px);
  left: calc(50% - 173px);
}

.btn_pre_loader {
  display: flex;
  align-items: center;
  padding: 40px 70px;
  font-size: 20px;
  background-color: #6237a0 !important;
  opacity: 1 !important;
  font-weight: 600;
}

.btn_pre_loader,
.btn_pre_loader:focus {
  outline: 0 !important;
  border: none !important;
}

.btn_pre_loader span {
  padding: 12px;
  margin-right: 15px;
}

.card-body {
  height: 70vh;
  padding: 0px 0px 0px 10px;
}

.card-body-request {
  padding: 0px 10px 0px 10px;
}

.products-list {
  height: 71vh;
  overflow-y: scroll;
}

.withdraw {
  display: flex;
}

.left-menu {
  height: calc(100vh - 45px);
  background-color: #222e3c;
  width: 90px;
  color: rgba(255, 255, 255, 0.95);
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2);
}

.content {
  width: 100%;
  padding: 10px 10px;
}

a {
  cursor: pointer;
}

.content .card {
  height: calc(100vh - 75px);
}

.content .card-header {
  background-color: #7e5bd3;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
}

.content .card-header p {
  margin-bottom: 0px;
}

.content-product {
  display: flex;
  margin-top: 10px;
  margin-bottom: 6px;
  align-items: center;
}

.content-product img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
}

.content-product .info {
  width: 85%;
}

.content-product .info p {
  margin-bottom: 0px;
  text-align: left;
  font-size: 15px;
  line-height: 16px;
}

.content .btn-save button {
  width: 100%;
  margin-top: 10px;
  margin-bottom:10px
}

/*=================================================================================================================*/
/*                                                   Requisição                                                    */
/*=================================================================================================================*/
.label-title {
  display: flex;
  margin-left: 2px;
  margin-bottom: 5px;
}

.content .justification label {
  display: flex;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-top: 10px;
  margin-left: 2px;
  margin-bottom: 2px;
}

.content .quantity label {
  display: flex;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-top: 7px;
  margin-left: 2px;
  margin-bottom: 2px;
}

.content .control label {
  display: flex;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-top: 7px;
  margin-left: 2px;
  margin-bottom: 2px;
}

.content-product-selected {
  height: 80px;
  display: flex;
  margin-bottom: 6px;
  align-items: center;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  padding: 15px;
}

.content-people-selected {
  height: 90px;
  display: flex;
  margin-bottom: 6px;
  align-items: center;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  padding: 15px 0px 15px 15px;
}

.content-product-selected img,
.content-people-selected img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
}

.content-product-selected .info,
.content-people-selected .info {
  width: 85%;
}

.content-product-selected .info p,
.content-people-selected .info p {
  margin-bottom: 0px;
  text-align: left;
  font-size: 15px;
  line-height: 16px;
}

.search{
  display:flex;
  margin: 10px 5px 3px 5px;
}

.search .input-search{
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.search .btn-search{
  background-color: #6237A0;
  border:none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 15px;
}

.search svg{
  color:#fff;
}

/*.card-contai5r {
    padding: 0 15px;
}

.content-card {
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 8px 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.item-card {
    margin-left: 40px;
}

.item-card .content-card {
    background-color: #f4f4f4;
    height: auto;
}

.item-title {
    padding: 10px 0;
    font-weight: 400;
    font-size: 18px;
}

.item-card .content-card {
    padding: 30px;
}

.content-container {
    display: flex;
    justify-content: center;
}

.request-content {
    width: 60%;
}

.item-title {
    padding-bottom: 40px;
}

.select-container {
    margin-bottom: 15px;
}

@media (max-width: 1200px) {
    .content {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }

    .item-card {
        margin-left: 0;
        padding: 30px 15px 0 15px;
    }

    .request-content {
        width: 70%;
    }
}

@media (max-width: 900px) {
    .item-card {
        padding: 5px 15px 0 15px;
    }

    .request-content {
        width: 85%;
    }
}*/
</style>

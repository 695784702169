<template>
	<div>
		<div @click="category('All', 'A1')" class="option-card" :class="{active: A_1}">Todos</div>
		<div class="option-card" :class="{active: A_2}">
			<div @click="category('Avental', 'A2')" class="icon-item apron-icon" ></div>
		</div>
		<div class="option-card" :class="{active: A_3}">
			<div @click="category('Calca', 'A3')" class="icon-item trousers-icon"></div>
		</div>
		<div class="option-card" :class="{active: A_4}">
			<div @click="category('Luva', 'A4')" class="icon-item gloves-icon" ></div>
		</div>
		<div class="option-card" :class="{active: A_5}">
			<div @click="category('Protetores', 'A5')" class="icon-item headphones-icon"></div>
		</div>
		<div class="option-card" :class="{active: A_6}">
			<div @click="category('Óculos', 'A6')" class="icon-item glasses-icon"></div>
		</div>
		<div class="option-card" :class="{active: A_7}">
			<div @click="category('Mascara', 'A7')" class="icon-item mask-icon"></div>
		</div>
		<div class="option-card" :class="{active: A_8}">
			<div @click="category('Creme', 'A8')" class="icon-item suncream-icon"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: "EpiMenu",
	data() {
        return {
            'A_1':true,
			'A_2':'',
			'A_3':'',
			'A_4':'',
			'A_5':'',
			'A_6':'',
			'A_7':'',
			'A_8':'',
        };
    },
	methods:{
		category(namecategory, id){

			console.log(id)

			this.A_1 = id == 'A1' ? true : false
			this.A_2 = id == 'A2' ? true : false
			this.A_3 = id == 'A3' ? true : false
			this.A_4 = id == 'A4' ? true : false
			this.A_5 = id == 'A5' ? true : false
			this.A_6 = id == 'A6' ? true : false
			this.A_7 = id == 'A7' ? true : false
			this.A_8 = id == 'A8' ? true : false

			this.$store.state.category = namecategory
		},
		AppCss(){
			Active
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.active{
	background-color: #18212c;;
}

.apron-icon {
	mask: url(../assets/apron.svg);
}

.trousers-icon {
	mask: url(../assets/trousers.svg);
}

.gloves-icon {
	mask: url(../assets/gloves.svg);
}

.headphones-icon {
	mask: url(../assets/headphones.svg);
}

.glasses-icon {
	mask: url(../assets/safety-glasses.svg);
}
.mask-icon {
	mask: url(../assets/gas-mask.svg);
}
.suncream-icon {
	mask: url(../assets/suncream.svg);
}

.option-card {
	transition: 0.1s;
	border-bottom: 1px solid rgba(0, 0, 0, 0.5);
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.option-card:first-child {
	border-top: 1px solid rgba(0, 0, 0, 0.5);
	text-transform: uppercase;
	height: 65px;
}

.option-card:hover {
	background-color: #18212c;
}

.icon-item {
	transition: 0.1s;
	height: 40px;
	width: 40px;
	mask-size: 40px;
	background-color: rgba(255, 255, 255, 0.95);
}

.option-card:hover .icon-item {
	height: 45px;
	width: 45px;
	mask-size: 45px;
	background-color: #fff;
}

@media (max-width: 500px) {
	.option-card {
		width: 65px;
	}
}
</style>

<template>
<div>
    <NavBar />
    <div class="matricula">
        <div class="matricula-card">
            <h2 class="matricula-title">Digite sua senha</h2>
            <h3 v-if="msg_error" class="msg_error">{{text_msg_error}}</h3>
            <br>
            <form class="matricula-form" action="javascript:void(0);" @submit="sendMatricula">
                <div class="input-container">
                    <Input type="password" name="password" placeholder="Senha" />
                </div>
                <div class="btn-container">
                    <Button buttonText="Entrar" buttonColor="#00aaff" />
                </div>
            </form>
            <div class="btn-container">
                <Button buttonText="Cancelar" buttonColor="red" linkTo="/" />
            </div>
        </div>
    </div>
    <div v-if="loader == true" class="pre_loader">
        <button class="btn btn-primary btn_pre_loader" type="button" disabled>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Processando ...
        </button>
    </div>
    <Footer />
</div>
</template>

<script>

import { baseApiUrl } from '../global';

import axios from '@/con'

import Button from "@/components/Button";
import Input from "@/components/Input";
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";

export default {
    name: "Matricula",
    data: function () {
        return {
            loader:false,
            msg_error: false,
            text_msg_error: ''
        }
    },
    components: {
        Button,
        Input,
        NavBar,
        Footer
    },
    methods: {
        sendMatricula(e) {

            this.loader = true
            this.msg_error = false

            //Recepção dos dados do Form
            let userData = {};

            let matricula;

            const form = e.target;
            const formData = new FormData(form);

            for (const [inputName, value] of formData) {
                userData[inputName] = value;
            }

            if (!userData["password"]) {
                this.loader = false
                this.text_msg_error = 'O campo senha é obrigatório!'
                this.msg_error = true
            } else {
                let url = window.location.href;

                matricula = url.substring(url.indexOf("?") + 1, url.length);

                var user = JSON.parse(localStorage.getItem('userKey'))

                const method = 'post'

                var data = {
                    password: userData["password"],
                    matriculation: matricula
                }

                var rotas = 'loginBalcao'

                const options = {
                    headers: {
                        "Accept": "application/json",
                        Authorization: `Bearer ${user.token}`
                    }
                }

                axios[method](`${baseApiUrl}/${rotas}`, data, options, {
                    timeout: 5000
                }).then(res => {

                    console.log(res)

                    this.loader = false

                    var matriculation = res.data.matriculation

                    if (res.status === 200 && res.data.resultLoginBalcao !== 'Falha no login') {

                        this.$router.push({
                            path: `/identification?${matriculation},senha`
                        })

                    } else {

                        console.log('falhou')
                        this.text_msg_error = 'Informações inválidas'
                        this.msg_error = true
                    }

                    }).catch((e) => {

                        if(e.response.status === 422){
                            this.text_msg_error = 'Informações inválidas'
                            this.msg_error = true
                        }

                        this.loader = false

                        /*if (e.code === 'ECONNABORTED') {

                        }*/
                    });
                }
            }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

.pre_loader {
  position: absolute;
  top: calc(50% - 58px);
  left: calc(50% - 150px);
}

.btn_pre_loader {
  display: flex;
  align-items: center;
  padding: 35px 60px;
  font-size: 20px;
  background-color: #6237a0 !important;
  opacity: 1 !important;
  font-weight: 600;
}

.btn_pre_loader,
.btn_pre_loader:focus {
  outline: 0 !important;
  border: none !important;
}

.btn_pre_loader span {
  padding: 12px;
  margin-right: 15px;
}

.matricula {
    height: calc(100vh - 90px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.matricula-card {
    transition: 1s;
    min-width: 250px;
    width: 30%;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 3vw;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.matricula-title {
    font-size:25px;
    color: rgb(80, 79, 79);
    width: 100%;
    text-align: center;
    margin-bottom: 45px;
    font-weight: 400;
}

.msg_error {
    font-size:20px;
    color: red
}

.input-container {
    margin-bottom: 15px;
}

.matricula-form {
    margin-bottom: 15px;
}

.btn-container:not(:last-child) {
    margin-bottom: 15px;
}

@media only screen and (min-width: 1400px) {
    .matricula-title {
        font-size: 30px;
    }

    .input-container {
        margin-bottom: 25px;
    }

    .matricula-form {
        margin-bottom: 25px;
    }

    .btn-container:not(:last-child) {
        margin-bottom: 25px;
    }
}

@media only screen and (max-width: 1200px) {
    .matricula-card {
        padding: 8vw;
        width: 50%;
    }
}
</style>

<template>
	<div>
		<NavBar />
		<div class="id-container">
			<div class="id-items">
				<div class="card-block">
					<div class="id-content">
						<div class="card-container">
							<Card
								cardName="Cadastrar Senha"
								iconName="key"
								linkTo="/matricula?cadastroSenha"
							/>
						</div>
						<!--<div v-if="false" class="card-container">
							<Card
								cardName="Cadastrar Biometria"
								iconName="fingerprint-scan"
								linkTo="/matricula?cadastroBiometria"
							/>
						</div>-->
						<div v-if="false" class="card-container">
							<Card
								cardName="Cadastrar Crachá"
								iconName="badge"
								linkTo="/matricula?cadastroCracha"
							/>
						</div>
					</div>
				</div>
				<div class="return-block">
					<div class="return-container">
						<Button buttonText="Voltar" linkTo="/" buttonIcon="return-arrow" />
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import Card from "@/components/Card";
import Button from "@/components/Button";

export default {
	name: "Id",
	components: {
		NavBar,
		Footer,
		Card,
		Button,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.id-container {
	width: 100vw;
	height: calc(100vh - 90px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.id-content {
	width: 60%;
	height: 70%;
	display: grid;
	/*grid-template-columns: 1fr 1fr;*/
}

.card-block,
.return-block {
	display: flex;
	align-items: center;
	justify-content: center;
}

.return-container {
	width: 30%;
}

.card-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.id-items {
	margin-top: 10%;
	margin-bottom: 10%;
	height: 80%;
	width: 100%;
	display: grid;
	grid-template-rows: 5fr 1fr;
}

.nav-link{
	padding: 0;
	width:50% !important;
}

@media (min-aspect-ratio: 5/10) {
	@media (max-aspect-ratio: 6/10) {
		.id-container {
			height: calc(100vh - 150px);
		}
	}
}

@media (max-width: 1200px) {
	.id-content {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr 1fr;
	}

	.id-items {
		height: auto;
		margin: 0;
		grid-template-rows: 10fr 1fr;
	}

	.id-container {
		overflow-y: scroll;
		align-items: flex-start;
	}

	.card-container {
		margin: 5% 0;
	}

	.return-container {
		width: 60%;
	}
}
</style>

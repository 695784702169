<template>
<div>
    <NavBar />
    <div class="matricula">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Confirma a autenticação?</h3>
            </div>
            <div class="card-body">
                <div class="row">
                    <label class="colaborador">{{name}}</label>
                    <hr>
                    <div class="col-md-6 info">
                        <label><b>Matrícula: </b> {{matriculation}}</label>
                        <label><b>Função: </b> {{role}}</label>
                        <label><b>Grupo: </b> {{group}}</label>
                        <label><b>Situação: </b>{{status}}</label>
                    </div>
                    <div class="col-md-6">
                        <div class="perfil">
                            <img src="@/assets/perfil.png" alt="">
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-6 btn-container">
                        <button @click="delivery" class="btn btn-primary btn-controle">Continuar</button>
                    </div>
                    <div class="col-md-6 btn-container">
                        <Button buttonText="Cancelar" class="btn-controle" buttonColor="red" linkTo="/" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="loader == true" class="pre_loader">
        <button class="btn btn-primary btn_pre_loader" type="button" disabled>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Processando ...
        </button>
    </div>
    <Footer/>
</div>
</template>

<script>

import {baseApiUrl} from "@/global.js";

import axios from '@/con'

import Button from "@/components/Button";
import Input from "@/components/Input";
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";

export default {
    name: "Matricula",
    data: function () {
        return {
            loader:false,
            msg_error: false,
            text_msg_error: '',
            name: '---',
            matriculation:'---',
            role:'---',
            group:'---',
            status:'---',
            metodo_auth: ''
        }
    },
    components: {
        Button,
        Input,
        NavBar,
        Footer
    },
    created() {

        let url = window.location.href;

        var size_second_params = url.substring(url.indexOf(",") + 1, url.length);

        var url_matriculation = url.substring(url.indexOf("?") + 1, url.length - (size_second_params.length + 1));

        var user = JSON.parse(localStorage.getItem('userKey'))

        this.metodo_auth = size_second_params

        if (url_matriculation.length > 0) {

            const method = 'post'
            var matriculation = url_matriculation
            var rotas = 'info_collaborator'

            const options = {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${user.token}`
                }
            }

            var data = {
                matriculation: matriculation
            }

            axios[method](`${baseApiUrl}/${rotas}`, data, options, {
                timeout: 5000
            }).then(res => {
                console.log(res.data.infoCollaborator[0])

                this.$store.state.matriculation = res.data.infoCollaborator[0].matriculation

                this.name = res.data.infoCollaborator[0].name
                this.matriculation = res.data.infoCollaborator[0].matriculation
                this.role = res.data.infoCollaborator[0].roleName
                this.group = res.data.infoCollaborator[0].groupName
                this.status = res.data.infoCollaborator[0].status ? 'Ativo' : 'Inativo'

            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {

                }
            });
        }
    },
    methods: {
        delivery() {
            this.loader = false
            this.$router.push({
                path: `/retirada?${this.metodo_auth}`
            })
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

.pre_loader {
  position: absolute;
  top: calc(50% - 58px);
  left: calc(50% - 175px);
}

.btn_pre_loader {
  display: flex;
  align-items: center;
  padding: 35px 60px;
  font-size: 20px;
  background-color: #6237a0 !important;
  opacity: 1 !important;
  font-weight: 600;
}

.btn_pre_loader,
.btn_pre_loader:focus {
  outline: 0 !important;
  border: none !important;
}

.btn_pre_loader span {
  padding: 12px;
  margin-right: 15px;
}
.matricula {
    height: calc(100vh - 90px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.card .card-header {
    background-color: #7E5BD3;
    border: 1px solid #7E5BD3;
}

.card .card-header h3 {
    font-size: 20px;
}

.card .card-body .colaborador {
    font-size: 20px;
    margin-bottom: 10px;
    ;
}

.card .card-body .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-right: 1px solid #CBCFD4;
}

.card- .card-body .perfil {
    display: flex;
    justify-content: center;
    align-items: center;
}

.perfil img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.btn-controle{
    border-radius: 100px;
}

button {
    transition: 0.1s;
    width: 100%;
    border: 0;
    background-color: #00aaff;
    color: #fff;
    padding: 16px 32px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-justify: center;
    cursor: pointer;
    outline: none;
}

button:hover {
    background-color: #0083ff;
}

.matricula-card {
    transition: 1s;
    min-width: 250px;
    width: 50%;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 10px 40px;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.card-title {
    color: #fff;
}

.matricula-title {
    color: rgba(0, 0, 0, 0.95);
    width: 100%;
    text-align: center;
    font-weight: 400;
}

.colaborador {
    font-size: 22px;
}

.msg_error {
    color: red
}

.input-container {
    margin-bottom: 15px;
}

.matricula-form {
    margin-bottom: 15px;
}

.btn-container:not(:last-child) {
    margin-bottom: 15px;
}

.nav-link {
    padding: 0;
}

@media only screen and (min-width: 1400px) {
    .matricula-title {
        font-size: 30px;
    }

    .input-container {
        margin-bottom: 25px;
    }

    .matricula-form {
        margin-bottom: 25px;
    }

    .btn-container:not(:last-child) {
        margin-bottom: 25px;
    }
}

@media only screen and (max-width: 1200px) {
    .matricula-card {
        padding: 8vw;
        width: 50%;
    }
}
</style>

<template>
    <div class="container-fluid">
        <NavBar />
        <div class="delimiter">
            <div class="row">
            <div class="col-md-4">
                <div class="perfil">
                    <div class="card">
                        <div class="card-header">
                            Atualização de senha
                        </div>
                        <div class="card-body">
                            <form class="perfil-form">
                                <div class="input-container">
                                    <input autocomplete="off" type="password" class="form-control" name="password" placeholder="Nova senha" v-model="password.senha"/>
                                </div>
                                <div class="input-container">
                                    <input autocomplete="off" type="password" class="form-control" name="confirmPassword" placeholder="Confirme nova senha" v-model="password.confirm"/>
                                </div>
                                <div class="btn-container">
                                    <div class="btnCancel">
                                        <button @click="backMenu" class="btn btn-danger" type="button">Voltar</button>
                                    </div>
                                    <div class="btnSave">
                                        <button :disabled="btnSave" @click="updatePassword" class="btn btn-primary" type="button">Salvar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div v-if="loaderdelivery == true" class="pre_loader_delivery">
                            <button class="btn btn-primary btn_pre_loader" type="button" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Processando ...
                            </button>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        </div>
    </div>
</template>
    
<script>
    import { baseApiUrlAdmin } from "@/global";

    import axios from '@/con'

    import NavBar from "@/components/NavBar";
    import Footer from "@/components/Footer";
    
    export default {
        name: "Matricula",
        data: function () {
            return {
                password:{},
                btnSave: false,
                loaderdelivery:false
            }
        },
        components: {
            NavBar,
            Footer
        },
        methods: {
            backMenu(){
                this.$router.push({
                	path: `/`
            	})
            },
            updatePassword() {

                let senha        = this.password.senha
                let confirmSenha = this.password.confirm

                if(!senha){
                    this.$toasted.global.defaultError({ msg: "O campo senha é obrigatório!" });
                    return;
                }

                if(!confirmSenha){
                    this.$toasted.global.defaultError({ msg: "O campo confirmação de senha é obrigatório!" });
                    return;
                }

                this.loaderdelivery = true
                this.btnSave = true

                const method = 'post'
                    var rotas = 'update_user'

                    const options = {
                        headers: {
                            "Accept": "application/json"
                        }
                    }

                    var operator = JSON.parse(localStorage.getItem("userKey"));

                    var params = {
                        id: operator.id,
                        password: this.password
                    }

                    axios[method](`${baseApiUrlAdmin}/${rotas}`, params, {
                        timeout: 5000
                    }, options).then(res => {

                        console.log(res.data)

                        this.password.senha   = ''
                        this.password.confirm = ''

                        this.loaderdelivery = false

                        this.$toasted.global.defaultSuccess({ msg: "Senha atualizada com sucesso!" });


                        setInterval(() => {
                            
                            this.btnSave = false

                        }, 3000);

                    }).catch((e) => {

                        this.btnSave = false
                        this.loaderdelivery = false

                        if (e.code === 'ECONNABORTED') {

                        }
                    });
            }
        }
    }
</script>
    

    
<style scoped>

/*========================================== css ok ====================================== */
.container-fluid{
    padding: 0px;
}

.delimiter{
    padding: 25px;
}

.perfil {
    height: 100vh;
}

.perfil .card-header{
    background-color: #7E5BD3;
    color:#fff;
    font-weight: 500;
    font-size:18px;
}

.perfil .card-body{
    padding-bottom: 0;
}

.btn-container{
    display: flex;
    justify-content: flex-end;
}

.btnSave{
    margin-left: 12px;
}

.pre_loader_delivery{
  position:absolute;
  top: calc(50% - 35px);
  left: calc(50% - 100px);
}

.btn_pre_loader{
  display: flex;
  align-items: center;
  padding: 18px 18px;
  font-size:20px;
  background-color: #6237A0 !important;
  opacity: 1 !important;
}

.btn_pre_loader, .btn_pre_loader:focus{
  outline: 0 !important;
  border:none !important;
}

.btn_pre_loader span{
  padding:8px;
  margin-right: 15px;
}

/*========================================== final ====================================== */

.perfil-card{
    transition: 1s;
    min-width: 250px;
    width: 100%;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 3vw;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.matricula-title {
    font-size:25px;
    color: rgb(80, 79, 79);
    width: 100%;
    text-align: center;
    margin-bottom: 45px;
    font-weight: 400;
}

.msg_error {
    font-size:20px;
    color: red
}

.input-container {
    margin-bottom: 25px;
}

.perfil-form {
    margin-top:10px;
    margin-bottom: 15px;
}

.btn-container:not(:last-child) {
    margin-bottom: 15px;
}

.nav-link {
    padding: 0;
}

@media only screen and (min-width: 1400px) {
    .perfil-title {
        font-size: 30px;
    }

    .input-container {
        margin-bottom: 25px;
    }

    .perfil-form {
        margin-bottom: 25px;
    }

    .btn-container:not(:last-child) {
        margin-bottom: 25px;
    }
}

@media only screen and (max-width: 1200px) {
    .matricula-card {
        padding: 8vw;
        width: 50%;
    }
}
</style>
    
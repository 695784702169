<template>
	<select
		v-model="message"
		class="select-input select-color"
		:class="fieldStyle"
		:name="name"
		@click="textChange(message, $event)"
	>
		<option class="default-option" value="0">{{ placeholder }}</option>
		<option :value="option" v-for="option in options" :key="option">{{
			option
		}}</option>
	</select>
</template>

<script>
export default {
	name: "Select",
	data() {
		return {
			message: 0,
		};
	},
	props: {
		name: { default: "inputarea" },
		placeholder: { default: "Campo de Texto" },
		options: {},
		fieldStyle: "",
	},
	methods: {
		textChange(textValue, e) {
			this.$emit("clicked", textValue);
			let select = e.target;
			if (select.value != "0") {
				select.classList.remove("select-color");
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.select-input {
	width: 100%;
	color: #495057;
	border: 1px solid #ced4da;
	transition: 0.15s;
	padding: 16px 32px;
	box-sizing: border-box;
	border-radius: 100px;
	font-size: 16px;
	outline: 0;
}

.select-color {
	color: #999;
}

.select-input option {
	color: #495057;
}

.select-input:focus {
	border: 1px solid #80bdff;
	box-shadow: 0 0 0 4px #bfdeff;
	position: relative;
}

.default-option {
	display: none;
}

.square {
	border-radius: 5px;
}
</style>

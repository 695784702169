<template>
<div>
    <NavBar />
    <div class="withdraw">
        <div class="content">
            <div class="card-container menu-card">
                <div class="content-card-list tabs-card">
                    <div class="pesquisa">
                        <input class="search-input" type="text" placeholder="Pesquisa" v-model="search"/>
                        <button @click="loadCollaborators" class="btn-search"><i class="fas fa-search"></i></button>
                    </div>
                    <div class="list-container">
                        <table class="table table-striped table-hover">
                            <tbody>
                                <tr v-for="(iten, index) in itens" :key="index">
                                    <td v-for="(i, index_1) in order(iten)" :key="index_1" @click="loadCollaborator(iten, index)">{{i}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-observe-visibility="visibilityChanged">...</div>
                    </div>
                </div>
            </div>
            <div class="card-container item-card">
                <div class="content-card">
                    <div class="title-container">
                        <h3 class="item-title"><b>Matrícula:</b> {{collaborator.matriculation}}</h3>
                        <h3 class="item-title"><b>Nome:</b> {{collaborator.name}}</h3>
                    </div>
                    <input type="hidden" name="id" v-model="collaborator.id">
                    <div class="content-container">
                        <div class="reset-card-content">
                            <div class="text-container cracha-container">
                                <div class="label_title"><b>Crachá:</b></div>
                                <input type="text" class="form-control" name="tag_name" v-model="collaborator.tag_name" readonly>
                            </div>
                            <div class="text-container  password-container">
                                <div class="label_title"><b>Senha:</b></div>
                                <input type="text" class="form-control" name="password" v-model="collaborator.password" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="buttons-container">
                        <div class="btn">
                            <button :disabled="clearTagName" @click="clear_tag_name('1')" class="btn btn-primary btn_clear">Limpar crachá</button>
                        </div>
                        <div class="btn">
                            <button :disabled="clearPassword" @click="clear_tag_name('2')" class="btn btn-primary btn_clear">Limpar senha</button>
                        </div>
                    </div>
                    <div v-if="false" class="buttons-container">
                        <div class="btn">
                            <button disabled ="reset_biometric()" class="btn btn-primary btn_clear">Reset biometria</button>
                        </div>
                    </div>
                    <div class="buttons-container">
                        <div class="btn">
                            <button @click="close()" class="btn btn-danger btn_clear">Fechar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="loader == true" class="pre_loader">
            <button class="btn btn-primary btn_pre_loader" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Processando ...
            </button>
        </div>
    </div>
</div>
</template>

<script>

import { baseApiUrl } from '../global';

import axios from '@/con'

import NavBar from "@/components/NavBar";
import TabsCard from "@/components/TabsCard";
import Select from "@/components/Select";
import Input from "@/components/Input";
import Button from "@/components/Button";

export default {
    name: "Redirect",
    data: function () {
        return {
            itens: [],
            collaborator: {},
            loader: false,
            indexCollaborator:0,
            clearTagName:true,
            clearPassword:true,
            page:0,
            search:''   
        }
    },
    components: {
        NavBar,
        TabsCard,
        Select,
        Input,
        Button
    },
    mounted() {

        this.collaborator.matriculation = '---'
        this.collaborator.firstName = '---'
        this.collaborator.tag_name = ''
        this.collaborator.password = ''
    },
    methods: {
        visibilityChanged(isVisible){

            if(!isVisible) return;

            this.page++

            this.loadCollaborators()
        },
        loadCollaborators() {

            this.loader = true

            var user = JSON.parse(localStorage.getItem('userKey'))

            const method = 'post'

            var rotas = 'listCollaboratorPage'

            const options = {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${user.token}`
                }
            }

            let params = {
                search: this.search
            }

            axios[method](`${baseApiUrl}/${rotas}?page=0&size=5`, params, options,{
                timeout: 20000
            }).then(res => {

                this.itens = []

                if(this.search.length > 0){

                    this.itens.push( ...res.data.listCollaborators);

                }else{

                    this.itens.push( ...res.data.listCollaborators);
                }
                
                this.loader = false

            }).catch((e) => {

                this.loader = false

                if (e.code === 'ECONNABORTED') {

                }
            });
        },
        order(iten) {

            var list = Object.values(iten)

            list = list.slice(4, 5)

            return list;
        },
        loadCollaborator(iten, index) {

            this.indexCollaborator = index

            this.collaborator.tag_name = ''
            this.collaborator.password = ''

            this.collaborator = {
                ...iten
            }

            this.clearTagName=false
            this.clearPassword=false
        },
        clear_tag_name(option) {

            this.loader = true
            this.clearTagName = true
            this.clearPassword = true
            
            var action = ''

            var user = JSON.parse(localStorage.getItem('userKey'))

            var id = this.collaborator.id

            if (option == '1') {

                if(!this.collaborator.tag_name.length > 0){

                    this.loader = false

                    this.$toasted.global.defaultError({ msg: "Reset  de crachá já realizado!" });

                    setTimeout(() => { 
                        this.clearTagName = false
                        this.clearPassword = false 
                    }, 3500)

                    return;
                }

                action = 'resetTagName'
            }
            if (option == '2') {

                if(!this.collaborator.password.length > 0 ){

                    this.loader = false

                    this.$toasted.global.defaultError({ msg: "Reset de senha já realizado!" });

                    setTimeout(() => { 
                        this.clearTagName = false
                        this.clearPassword = false 
                    }, 3500)

                    return;
                }

                action = 'resetPassword'
            }

            const method = 'post'

            var data = {
                id,
                action
            }

            var rotas = 'resetPasswordTagname'

            const options = {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${user.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, data, options,{
                timeout: 20000
            }).then(res => {

                console.log(res.data)

            if (res.status === 200 && option == '1') {
                this.collaborator.tag_name = ''
                this.$toasted.global.defaultSuccess({ msg: "Operação realizada com sucesso!" });
                this.itens[this.indexCollaborator].tag_name = ''
            }

            if (res.status === 200 && option == '2') {
                this.collaborator.password = ''
                this.$toasted.global.defaultSuccess({ msg: "Operação realizada com sucesso!" });
                this.itens[this.indexCollaborator].password = ''
            }

            this.loader = false
            setTimeout(() => { 
                this.clearTagName = false
                this.clearPassword = false 
            }, 3500)

            }).catch((e) => {

                this.loader = false
                setTimeout(() => { 
                    this.clearTagName = false
                    this.clearPassword = false 
                }, 3500)

                if (e.code === 'ECONNABORTED') {

                }
            });
        },
        /*loadPeopleSearch() {

            this.page = 1

        

            this.loader = true

            const method = 'post'

            var rotas = 'list_all_people_local'

            const options = {
                headers: {
                    "Accept": "application/json"
                }
            }

            var params = {
                search: this.search,
                page: this.page
            }


            axios[method](`${baseApiUrl}/${rotas}`, params, {
                timeout: 20000
            }, options).then(res => {

         

                this.itens = []

                this.itens.push( ...res.data.list_all_people_local.data);
                
                this.loader = false

            }).catch((e) => {


                this.loader = false

                if (e.code === 'ECONNABORTED') {

                }
            });
        },
        */
        close(){
            this.$router.push({
                path: `/`
            })
        },
        reset_biometric(){

            const method = 'get'

            var rotas = 'reset_biometric'

            var matricula = this.people.people_matriculation

            const options = {
                headers: {
                    "Accept": "application/json"
                }
            }

            axios[method](`${baseApiUrl}/${rotas}/${matricula}`, {
                timeout: 5000
            }, options).then(res => {

                if(res.data.code == 2000){

                    this.$toasted.global.defaultSuccess({msg: ''});
                }else{
                    this.$toasted.global.defaultError({msg: ''});
                }
            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {

                }
            });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.pesquisa{
    display: flex;
    margin-right: 10px;
    margin-left: 10px;
}

.btn-search{
    border:none;
    color:#fff;
    background-color: #6237A0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.pre_loader{
  position:absolute;
  top: calc(50% - 58px);
  left: calc(50% - 173px);
}

.btn_pre_loader{
  display: flex;
  align-items: center;
  padding: 40px 70px;
  font-size:25px;
  background-color: #6237A0 !important;
  opacity: 1 !important;
}

.btn_pre_loader, .btn_pre_loader:focus{
  outline: 0 !important;
  border:none !important;
}

.btn_pre_loader span{
  padding:12px;
  margin-right: 15px;
}

.card-container {
    height: 92vh;
    overflow-y: hidden;
    padding: 10px 15px;
}

.content-card-list {
    height: calc(100% - 39px);
    background-color: #fff;
    border: 1px solid #222e3c;
    border-radius: 10px;
    padding: 10px 0px 10px 0px;
}

.content-card {
    box-sizing: border-box;
    height: calc(100% - 39px);
    background-color: #fff;
    border: 1px solid #222e3c;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px;
}

.tab:hover {
    background-color: #18212c;
    color: #fff;
}

.search-input {
    box-sizing: border-box;
    transition: 0.1s;
    padding: 8px 16px;
    border: 1px solid #222e3c;
    width: 100%;
    outline: 0;
    font-size: 16px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.search-input:focus {
    box-shadow: 0 0 4px 2px rgba(34, 46, 60, 0.5);
}

.tabs-card {
    display: grid;
    grid-template-rows: 36px auto;
}

.list-container {
    overflow-y: scroll;
    box-sizing: border-box;
    /*border: 1px solid #222e3c;*/
    margin-top: 10px;
    border-radius: 5px;
    /*background-color: #e2e2e2;*/
}


.two-tabs .content-card {
    border-top-right-radius: 5px;
}

.no-tab .content-card {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    height: 100%;
}

.no-tab {
    height: 100%;
}

td {
    text-align: left;
    cursor: pointer
}

input,
input:focus {
    text-align: center;
    padding: 5px;
    border: none;
    background-color: #fff !important;
    outline: 0;
}

/* ============================================================================================================ */
.withdraw {
    display: flex;
}

.content {
    box-sizing: border-box;
    height: calc(100vh - 45px);
    width: 100%;
    padding: 20px 40px;
    display: grid;
    grid-template-columns: 3fr 7fr;
}

.card-container {
    padding: 0 15px;
}

.item-card {
    margin-left: 40px;
}

.content-card {
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
}

.item-card .content-card {
    background-color: #fff;
    height: auto;
}

.item-title {
    padding: 10px 0;
    font-weight: 500;
    font-size: 18px;
}

.item-card .content-card {
    padding: 30px 30px 7px 30px;
}

.content-container {
    display: flex;
    justify-content: center;
}

.item-title {
    padding-bottom: 20px;
}

.select-container {
    margin-bottom: 15px;
}

.title-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid #222e3c;
    margin-bottom: 30px;
}

.reset-card-content {
    padding-bottom: 30px;
    border-bottom: 1px solid #222e3c;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
}

.text-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 10px 10px;
    margin: 20px;
    border-radius: 5px;
}

.label_title {
    margin-bottom: 15px;
}

.cracha-container {
    border: 1px solid #0062ca;
}

.password-container {
    border: 1px solid #3c90c9;
}

.buttons-container {
    margin-top: 20px;
    display: flex;
}

.btn {
    width: 100% !important;
    margin: 0;
}

.btn_clear {
    border-radius: 5px;
    padding: 10px;
}

@media (max-width: 1200px) {
    .content {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }

    .item-card {
        margin-left: 0;
        padding: 30px 15px 0 15px;
    }
}

@media (max-width: 900px) {
    .content {
        padding: 20px;
    }

    .card-container {
        box-sizing: border-box;
        width: calc(100vw - 40px);
    }

    .text-container {
        box-sizing: border-box;
    }
}
</style>

<template>
<div>
    <NavBar />
    <div class="matricula">
        <div class="matricula-card">
            <h2 class="matricula-title">Digite sua senha</h2>
            <h3 v-if="msg_error" class="msg_error">{{text_msg_error}}</h3>
            <br>
            <form class="matricula-form" action="javascript:void(0);" @submit="sendMatricula">
                <div class="input-container">
                    <Input type="password" name="password" placeholder="Senha" />
                </div>
                <div class="btn-container">
                    <Button buttonText="Salvar" buttonColor="green" />
                </div>
            </form>
            <div class="btn-container">
                <Button buttonText="Cancelar" buttonColor="red" linkTo="/" />
            </div>
        </div>
    </div>
    <Footer />
</div>
</template>

<script>

import { baseApiUrl } from '../global';

import axios from '@/con'

import Button from "@/components/Button";
import Input from "@/components/Input";
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";

export default {
    name: "Matricula",
    data: function () {
        return {
            msg_error: false,
            text_msg_error: ''
        }
    },
    components: {
        Button,
        Input,
        NavBar,
        Footer
    },
    methods: {
        sendMatricula(e) {

            var user = JSON.parse(localStorage.getItem('userKey'))

            //Recepção dos dados do Form
            let userData = {};

            var action;
            var id;

            const form = e.target;
            const formData = new FormData(form);

            for (const [inputName, value] of formData) {
                userData[inputName] = value;
            }

            if (!userData["password"]) {
                this.text_msg_error = 'O campo senha é obrigatório!'
                this.msg_error = true
            } else {
                let url = window.location.href;

                id = url.substring(url.indexOf(",") + 1, url.length);

                action = url.substring(url.indexOf("?") + 1, url.length - (id.length + 1));

                // Redirecionamento para página de "Redirecionar"... Mensagem que aparece, e cor depois do #
                if (action == "number_1") {

                    const method = 'post'

                    var rotas = 'resetPasswordTagname'

                    const options = {
                        headers: {
                            "Accept": "application/json",
                            Authorization: `Bearer ${user.token}`
                        }
                    }

                    var data = {
                        id:id,
                        password: userData["password"],
                        action: 'changePassword'
                    }

                    axios[method](`${baseApiUrl}/${rotas}`, data, options,{
                        timeout: 5000
                    }).then(res => {

                        console.log(res.data)

                        if (res.status === 200) {

                            this.$router.push({
                                path: '/redirect'
                            })

                        } else {
                            this.$toasted.global.defaultError({ msg: "Falha no cadastro da senha!" });
                        }

                    }).catch((e) => {

                        console.log(e)

                        if (e.code === 'ECONNABORTED') {

                        }
                    });
                }
            }

            /*if (userData["matricula"] == 620) {
                window.location.href =
                    "http://localhost:8081/redirect?" +
                    encodeURI("Sua senha já foi cadastrada anteriormente#red");
            } else {
                window.location.href =
                    "http://localhost:8081/redirect?" +
                    encodeURI("Cadastro Realizado com sucesso#green");
            }
        } else if (url == "cadastroCracha") {
            alert("Falta Back-End!");
        } else {
            alert("URL Incorreto!");
        }*/
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.matricula {
    height: calc(100vh - 90px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.matricula-card {
    transition: 1s;
    min-width: 250px;
    width: 30%;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 3vw;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.matricula-title {
    font-size:25px;
    color: rgb(80, 79, 79);
    width: 100%;
    text-align: center;
    margin-bottom: 45px;
    font-weight: 400;
}

.msg_error {
    font-size:20px;
    color: red
}

.input-container {
    margin-bottom: 15px;
}

.matricula-form {
    margin-bottom: 15px;
}

.btn-container:not(:last-child) {
    margin-bottom: 15px;
}

@media only screen and (min-width: 1400px) {
    .matricula-title {
        font-size: 30px;
    }

    .input-container {
        margin-bottom: 25px;
    }

    .matricula-form {
        margin-bottom: 25px;
    }

    .btn-container:not(:last-child) {
        margin-bottom: 25px;
    }
}

@media only screen and (max-width: 1200px) {
    .matricula-card {
        padding: 8vw;
        width: 50%;
    }
}
</style>

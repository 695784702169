import axios from 'axios';

const sessao = localStorage.getItem('userKey');
let token;

if (sessao) {
  const user = JSON.parse(sessao);
  token = user.token;
} else {
  if (window.location.pathname !== '/login') {
    window.location.href = "/login";
  }
}

// Verifique se o token é válido antes de usá-lo
if (token) {
  // Adicione o token ao cabeçalho de todas as solicitações
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export default axios;
<template>
	<div>
		<div class="footer">
			<div class="foot-content">
				<div class="foot-text left-text">{{ this.connected  }}</div>
				<div class="scd-content">
					<div class="foot-text">{{this.hostname}}</div>
					<div class="foot-text">{{this.version}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {version} from '../../package'
export default {
	name: "Footer",
	data() {
		const os = require('os');

		return {
		hostname : os.hostname(),
		connected : navigator.online ? "Sistema ONLINE" : "Sistema Online",
		version : version
	}}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
	background-color: #6237A0;;
	height: 45px;
	grid-template-columns: 1fr 1fr;
	position: absolute;
	bottom: 0;
	width: 100vw;
	color: #fff;
	font-size: 15px;
}

.footer div {
	opacity: 0.95;
}

.foot-text {
	margin: auto 0;
}

.foot-content {
	padding-left: 20px;
	padding-right: 20px;
	width: calc(100% - 40px);
	height: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	text-align: left;
}

.scd-content {
	text-align: center;
	display: grid;
	grid-template-columns: 3fr 3fr 3fr 2fr;
}

@media only screen and (max-width: 500px) {
	.footer {
		font-size: 10px;
	}
	.foot-content {
		grid-template-columns: 2fr 3fr;
	}
}
</style>

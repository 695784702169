<template>
	<router-link
		class="nav-link"
		:class="{ small: cardSize == 'smallCard' }"
		:to="linkTo"
	>
		<div class="card" :class="cardSize">
			<div class="card-content">
				<div class="icon-container">
					<div
						v-if="!cardSpinner"
						class="card-icon"
						:class="cardSize"
						:style="iconStyle"
					></div>
					<ClipLoader
						v-if="cardSpinner"
						class="loader"
						color="#fff"
						:size="spinnerSize"
						:style="size"
					/>
				</div>

				<div>
					<div class="card-title">{{ cardName }}</div>
					<div class="card-description" v-if="iconDescription">
						{{ iconDescription }}
					</div>
				</div>
			</div>
		</div>
	</router-link>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
	name: "Card",
	data() {
		return {
			spinnerSize: "",
		};
	},
	props: {
		iconName: { default: "id-card" },
		cardName: { default: "Título" },
		cardSize: { default: "bigCard" },
		linkTo: { default: "#" },
		iconDescription: { default: "" },
		cardSpinner: { default: false },
		cardLink: {},
	},
	components: { ClipLoader },
	computed: {
		iconStyle() {
			return {
				"--mask-name":
					"url(" + require("@/assets/" + this.iconName + ".svg") + ")",
			};
		},
		size() {
			this.spinnerSize = "110px";

			const mediaQuery = window.matchMedia("(min-width: 1400px)");

			if (mediaQuery.matches) {
				this.spinnerSize = "150px";
			} else {
				const mediaQuery = window.matchMedia("(max-width: 1200px)");
				if (mediaQuery.matches) {
					const mediaQuery = window.matchMedia("(min-height: 800px)");
					if (mediaQuery.matches) {
						this.spinnerSize = "200px";
					}
				}
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav-link {
	width: 80% !important;
	text-decoration: none;
	box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.card {
	border-radius: 3px;
	background-color: #7E5BD3;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.bigCard {
	height: 38vh;
}

.bigCard .card-icon {
	height: 110px;
	width: 110px;
	mask-size: 110px;
}

.bigCard .card-title {
	font-size: 22px;
}

.smallCard {
	height: 35vh;
}

.smallCard .card-content .icon-container .card-icon {
	mask-size: 70px;
	height: 70px;
	width: 70px;
}

.smallCard .card-title {
	font-size: 18px;
}

@media only screen and (min-width: 1400px) {
	.bigCard .card-icon {
		height: 150px;
		width: 150px;
		mask-size: 150px;
	}

	.bigCard .card-title {
		font-size: 28px;
	}

	.smallCard .card-content .icon-container .card-icon {
		mask-size: 100px;
		height: 100px;
		width: 100px;
	}

	.smallCard .card-title {
		font-size: 22px;
	}
}

@media only screen and (max-width: 1200px) {
	@media only screen and (min-height: 800px) {
		.bigCard .card-icon {
			height: 200px;
			width: 200px;
			mask-size: 200px;
		}

		.bigCard .card-title {
			font-size: 35px;
		}

		.smallCard .card-content .icon-container .card-icon {
			mask-size: 120px;
			height: 120px;
			width: 120px;
		}

		.smallCard .card-title {
			font-size: 28px;
		}
	}
}

@media only screen and (max-width: 500px) {
	.bigCard {
		height: 34vh;
	}
}

@media only screen and (max-width: 400px) {
	.bigCard .card-icon {
		height: 120px;
		width: 120px;
		mask-size: 120px;
	}

	.bigCard .card-title {
		font-size: 22px;
	}

	.smallCard .card-content .icon-container .card-icon {
		mask-size: 100px;
		height: 100px;
		width: 100px;
	}

	.smallCard .card-title {
		font-size: 20px;
	}
}

.card-content,
.icon-container {
	width: 100%;
}

.icon-container {
	display: flex;
	justify-content: center;
}

.card-icon {
	mask: var(--mask-name);
	background: #fff;
}

.card-title {
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-top: 5%;
	color: #fff;
	padding: 0 10px;
}

.card:hover .card-icon {
	background-color: #f3b8be;
}
.card:hover .card-title,
.card:hover .card-description {
	color: #f3b8be;
}

.card-description {
	color: #f3b8be;
}
</style>

<template>
	<div>
		<NavBar />
		<DevicesCard />
		<Footer />
	</div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import Input from "@/components/Input";
import Button from "@/components/Button";
import DevicesCard from "@/components/DevicesCard";

export default {
	name: "DevicePage",
	components: {
		NavBar,
		Footer,
		Input,
		Button,
		DevicesCard,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
	<div>
		<NavBar />
		<div class="withdraw">
			<div class="content">
				<ListPeople/>
				<!--<TabsCard class="first-card" reset="no-tab" />
				<div class="balance-container">
					<div class="balance-card">
						<div class="content-card">
							<div class="title-container">
								<h3 class="item-title">Matrícula: 1998</h3>
								<h3 class="item-title">Pedro Lima</h3>
							</div>

							<div class="info-content">
								<div class="info-row">
									<div class="info-card">
										<div class="info-title">Unidade Atual</div>
										<div class="info-text">ASW</div>
									</div>
									<div class="info-card">
										<div class="info-title">Área Atual</div>
										<div class="info-text">ENG</div>
									</div>
									<div class="info-card">
										<div class="info-title">Setor Atual</div>
										<div class="info-text">ENG</div>
									</div>
								</div>
								<div class="info-row">
									<div class="info-card">
										<div class="info-title">Centro Custo</div>
										<div class="info-text">001</div>
									</div>
									<div class="info-card">
										<div class="info-title">Grupo Atual</div>
										<div class="info-text">Padrão</div>
									</div>
									<div class="info-card">
										<div class="info-title">Situação</div>
										<div class="info-text">Ativo</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="balance-list-container">
						<TabsCard reset="no-tab" />
					</div>
				</div>-->
			</div>
		</div>
	</div>
</template>

<script>
import NavBar from "@/components/NavBar";
import TabsCard from "@/components/TabsCard";
import ListPeople from '@/components/ListPeople';

export default {
	name: "Balance",
	components: { NavBar, TabsCard, ListPeople },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>




/*========================================================================================================================== */
.withdraw {
	display: flex;
}

.content {
	box-sizing: border-box;
	height: calc(100vh - 45px);
	width: 100%;
	padding: 10px 10px;
}

.balance-container {
	margin: 0 10px;
	overflow-y: scroll;
}

.balance-container::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

/* Track */
.balance-container::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
}

/* Handle */
.balance-container::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.2);
	border-radius: 10px;
}

.balance-list-container {
	height: 100%;
}

.balance-card {
	padding: 0 15px;
	margin-bottom: 30px;
}

.balance-card:last-child {
	margin-left: 40px;
}

.content-card {
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 8px 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.balance-card .content-card {
	background-color: #fff;
}

.item-title {
	padding-bottom: 20px;
	font-weight: 500;
	font-size: 18px;
}

.balance-card .content-card {
	padding: 40px;
}

.title-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	border-bottom: 1px solid #222e3c;
	margin-bottom: 30px;
}

.info-content {
	width: 100%;
	border: 1px solid #222e3c;
	border-radius: 5px;
	display: grid;
	grid-template-rows: 1fr 1fr;
}

.info-row {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.info-row:first-child {
	border-bottom: 1px solid #222e3c;
}

/* .info-card:not(:last-child) {
	border-right: 1px solid #222e3c;
} */

.info-title {
	padding: 10px;
	font-weight: 700;
}

.info-text {
	padding-bottom: 10px;
}

@media (max-width: 1200px) {
	.content {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 2fr;
		padding: 20px;
	}

	.balance-card {
		margin-top: 30px;
	}

	.balance-container {
		margin: 0;
	}

	.first-card {
		padding: 0 20px;
	}
}
</style>

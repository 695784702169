<template>
	<div>
		<NavBar />
		<div class="id-container">
			<div class="id-items">
				<div class="header-block">
					<div class="header-container">
						<h3 class="title">Dispositivos</h3>
					</div>
				</div>
				<div class="card-block">
					<div class="id-content">
						<div class="card-container">
							<Card
								cardName="Impressora"
								iconName="printer"
								linkTo="/pagDispositivo?impressora"
							/>
						</div>
						<div class="card-container">
							<Card
								cardName="Leitor de Crachá"
								iconName="scanner"
								linkTo="/pagDispositivo?cracha"
							/>
						</div>
						<div class="card-container">
							<Card
								cardName="Leitor Biométrico"
								iconName="fingerprint-scanner"
								linkTo="/pagDispositivo?biometrico"
							/>
						</div>
					</div>
				</div>
				<div class="return-block">
					<div class="return-container">
						<Button
							buttonText="Voltar"
							linkTo="/#2"
							buttonIcon="return-arrow"
						/>
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import Card from "@/components/Card";
import Button from "@/components/Button";

export default {
	name: "Sync",
	components: {
		NavBar,
		Footer,
		Card,
		Button,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.id-container {
	width: 100vw;
	height: calc(100vh - 90px);
	display: flex;
	align-items: center;
	justify-content: center;
}

.id-content {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

.card-block,
.return-block {
	display: flex;
	align-items: center;
	justify-content: center;
}

.return-container {
	width: 30%;
}

.card-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.id-items {
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-rows: 2fr 5fr 2fr;
}

.title {
	font-size: 18px;
	padding: 20px;
	text-transform: uppercase;
}

.nav-link {
    padding: 0;
}

@media (min-aspect-ratio: 5/10) {
	@media (max-aspect-ratio: 6/10) {
		.id-container {
			height: calc(100vh - 150px);
		}
	}
}

@media (max-width: 1200px) {
	.id-content {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr 1fr;
	}

	.id-items {
		height: auto;
		margin: 0;
		grid-template-rows: 1fr 10fr 1fr;
	}

	.id-container {
		overflow-y: scroll;
		align-items: flex-start;
	}

	.card-container {
		margin: 5% 0;
	}

	.return-container {
		width: 60%;
	}

	@media (min-width: 700px) {
		.title {
			font-size: 27px;
			padding-top: 35px;
		}
	}
}
</style>

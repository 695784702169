<template>
  <div>
    <NavBar />
    <div class="withdraw">
      <div v-if="false" class="left-menu">
        <EpiMenu />
      </div>
      <div class="content">
        <div class="card">
          <div class="card-body body-list-product">
            <nav>
              <div class="nav nav-tabs nav-justified" id="nav-tab" role="tablist">
                <button @click="abaProduct" class="nav-link active" id="nav-home-tab" data-bs-toggle="tab"
                  data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">
                  Permitidos
                </button>
                <button @click="abaRequest" class="nav-link" id="nav-profile-tab" data-bs-toggle="tab"
                  data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile"
                  aria-selected="false">Requisitados</button>
                <button v-if="false" class="nav-link" id="nav-contact-tab" data-bs-toggle="tab"
                  data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact"
                  aria-selected="false">Devolução</button>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                <div class="container">
                  <div class="search">
                    <input type="text" class="form-control input-search" placeholder="Pesquisa" v-model="search" />
                    <button @click="listProductsRoleUserPermission" class="btn-search"><i
                        class="fas fa-search"></i></button>
                  </div>
                  <div class="products-list">
                    <table class="table table-sm">
                      <tbody>
                        <tr v-for="(iten, index) in listProductsRoleUser" :key="index">
                          <td :id="index" :class="{ destaque: index == index_selecionado }">
                            <a @click="selectRoleProduct(iten, 'list', index)">
                              <div class="content-product">
                                <div class="logo">
                                  <img src="@/assets/perfil.png" />
                                </div>
                                <div class="info">
                                  <p><b>Código:</b> {{ iten.productCode }}</p>
                                  <p>
                                    <b>Produto:</b> {{ iten.productName }}
                                  </p>
                                  <p>
                                    <b>Unidade:</b>
                                    {{ iten.productQuantity }} {{ iten.unit_measurementsName }}
                                  </p>
                                </div>
                                <div class="quantity_list_green_list"
                                  :class="{ quantity_list_red_list: iten.users_quantitys_periods_quantity == '0' }">
                                  <p>
                                    {{ iten.categoryQuantity }}
                                  </p>
                                </div>
                              </div>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-if="loader == true" class="pre_loader">
                      <button class="btn btn-primary btn_pre_loader" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Carregando ...
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                <div class="container">
                  <div v-if="false" class="search">
                    <input type="text" class="form-control" placeholder="Pesquisa" />
                  </div>
                  <div class="products-list">
                    <table class="table table-sm">
                      <tbody>
                        <tr v-for="(iten, index) in listProductsRequestUser" :key="index">
                          <td>
                            <a @click="selectProductRequest(iten)">
                              <div class="content-product">
                                <div class="logo">
                                  <img src="@/assets/perfil.png" />
                                </div>
                                <div class="info">
                                  <p><b>Código:</b> {{ iten.code_product }}</p>
                                  <p><b>Produto:</b>{{ iten.name_product }}</p>
                                  <p>
                                    <b>Unidade:</b> {{ iten.quantity_request_product }}
                                  </p>
                                </div>
                                <div class="quantity_list_green_list" :class="{
                                  quantity_list_red: iten.users_quantitys_periods_quantity == '0'
                                }">
                                  <p>2</p>
                                </div>
                              </div>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div v-if="true" class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                ...
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h3 class="item-title">Selecione um Item</h3>
          </div>
          <div class="card-body body-image">
            <div class="image-product">
              <img :src="require(`@/assets/` + caminho)" alt="" />
            </div>
            <div class="control">
              <label v-if="msg_warning" class="msg-warning">{{ this.msg_warning }}</label>
              <label><b>Quantidade de itens</b></label>
              <input type="hidden" class="form form-control quantity-add" v-model="quantity_product" min="1" />
              <br />
              <button :disabled="btnAdddisabled" v-if="delivery_product == true" @click="addDeliveryRole" type="button"
                class="btn btn-primary btn-add" id="btn_item">{{ btn_name }}</button>
              <button :disabled="btnAdddisabled" v-if="delivery_request == true" @click="addDeliveryRequest" type="button"
                class="btn btn-primary btn-add" id="btn_item">{{ btn_name }}</button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h3>Itens para retirada</h3>
          </div>
          <div class="card-body body-delivery">
            <div v-if="delivery_product == true" class="products-delivery">
              <label v-if="msg_success" class="msg-success"><b>{{ msg_success }}</b></label>
              <label v-if="msg_error" class="msg-error"><b>{{ msg_error }}</b></label>
              <table class="table table-sm">
                <tbody>
                  <tr v-for="(iten, index) in productsDeliveryRole" :key="index">
                    <td id="index" :class="{ destaque: index == index_selecionado_delivery }">
                      <a>
                        <div class="content-product">
                          <div class="logo">
                            <img src="@/assets/perfil.png" />
                          </div>
                          <div class="info">
                            <p><b>Código:</b> {{ iten.productCode }}</p>
                            <p><b>Produto:</b> {{ iten.productName }}</p>
                            <p><b>Unidade:</b> {{ iten.productQuantity }} {{ iten.unit_measurementsName }}</p>
                          </div>
                          <div class="quantity-delivery-role">
                            <div class="quantity-delivery">
                              <button class="btn btn-danger btn-sm btn-quantity-less"
                                @click="selectQuantityRoleItem('less', index, iten.productId)">-</button>
                              <input class="quantity-select form-control form-control-sm" type="text"
                                v-model="quantitySelectProductRole[index].quantity">
                              <button class="btn btn-success btn-sm btn-quantity-more"
                                @click="selectQuantityRoleItem('more', index, iten.productId)">+</button>
                              <!--<p>{{ iten.quantity_delivery_product }}</p>-->
                            </div>
                            <div class="quantity-delete">
                              <button class="btn btn-link btn-sm btn-delete"
                                @click="deleteProductlistRole(index, iten.id)">Excluir</button>
                            </div>
                          </div>
                        </div>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="loaderdelivery1 == true" class="pre_loader_delivery">
                <button class="btn btn-primary btn_pre_loader" type="button" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Processando ...
                </button>
              </div>
            </div>
            <div v-if="delivery_request == true" class="products-delivery">
              <label v-if="msg_success" class="msg-success"><b>{{ msg_success }}</b></label>
              <table class="table table-sm">
                <tbody>
                  <tr v-for="(iten, index) in productsDeliveryRequest" :key="index">
                    <td>
                      <a>
                        <div class="content-product">
                          <div class="logo">
                            <img src="@/assets/perfil.png" />
                          </div>
                          <div class="info">
                            <p><b>Código:</b> {{ iten.code_product }}</p>
                            <p>
                              <b>Produto:</b> {{ iten.name_product }}
                            </p>
                            <p><b>Unidade:</b> {{ iten.product_quantity }}</p>
                          </div>
                          <div class="quantity-delivery-request">
                            <div class="quantity-delivery">
                              <button class="btn btn-danger btn-sm btn-quantity-less"
                                @click="selectQuantityRequestItem('less', index, iten.id)">-</button>
                              <input class="quantity-select form-control form-control-sm" type="text"
                                v-model="quantitySelectProductRequest[index].quantity">
                              <button class="btn btn-success btn-sm btn-quantity-more"
                                @click="selectQuantityRequestItem('more', index, iten.id)">+</button>
                              <!--<p>{{ iten.quantity_delivery_product }}</p>-->
                            </div>
                            <div class="quantity-delete">
                              <button class="btn btn-link btn-sm btn-delete"
                                @click="deleteProductlistRequest(index, iten.id)">Excluir</button>
                            </div>
                          </div>
                        </div>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="loader1 == true" class="pre_loader1">
                <button class="btn btn-primary btn_pre_loader1" type="button" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Carregando ...
                </button>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="menu-button">
              <div v-if="false">
                <button class="btn btn-info btn-basket">
                  <span>
                    <i class="fas fa-shopping-basket"></i>
                  </span>
                  <span>Cesta</span>
                </button>
              </div>
              <div v-if="delivery_product == true">
                <button :disabled="btnSaveRoledisabled" @click="deliveryRole" class="btn btn-primary btn-save">
                  <span>
                    <i class="far fa-save"></i>
                  </span>
                  <span>Salvar</span>
                </button>
              </div>
              <div v-if="delivery_request">
                <button :disabled="btnSaveRequestdisabled" @click="deliveryRequest" class="btn btn-primary btn-save">
                  <span>
                    <i class="far fa-save"></i>
                  </span>
                  <span>Salvar</span>
                </button>
              </div>
              <div>
                <button :disabled="btnCanceldisabled" @click="close" class="btn btn-danger btn-exit">
                  <span>
                    <i class="far fa-window-close"></i>
                  </span>
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { baseApiUrl } from "../global";

import axios from '@/con';

import NavBar from "@/components/NavBar";
import TabsCard from "@/components/TabsCard";
import EpiMenu from "@/components/EpiMenu";

export default {
  name: "Redirect",
  data() {
    return {
      loader: false,
      loaderdelivery1: false,
      btnAdddisabled: true,                                     // OK - Desabilita o botão adicionar no processo de entrega dos itens
      btnSaveRoledisabled: true,                                // OK - Desabilita o botão Salvar no processo de entrega dos itens
      btnSaveRequestdisabled: true,                             // OK - Desabilita o botão Salvar no processo de entrega dos itens
      btnCanceldisabled: false,                                 // OK - Desabilita o botão Cancelar no processo de entrega dos itens
      index_selecionado: -1,
      listProductsRoleUser: [],                                 // OK - Lista de produtos permitidos via regra para o colaborador em questão
      listProductsRequestUser: [],                              // OK - Lista de produtos permitidos via requisição para o colaborador em questão 
      productRoleSelected: {},                                  // OK - Armazena o iten selecionado via regra na lista de itens permitidos
      productRequestSelected: {},                               // OK - Armazena o iten selecionado via requisição na lista de itens permitidos
      productsDeliveryRole: [],                                 // OK - Armazena a lista de itens a ser entregue por regra
      productsDeliveryRequest: [],                              // OK - Armazena a lista de itens a ser entregue por r
      quantitySelectProductRole: [],                            // OK - Quantidade de produto escolhida a ser retirada via regra
      quantitySelectProductRequest: [],                         // OK - Quantidade de produto escolhida a ser retirada via requisição
      collaborator: '',
      search: '',

      quantityPermission: '1',


      caminho: "perfil.png",
      msg_warning: "",
      msg_success: "",
      msg_error: "",
      quantity_product: "1",
      delivery_product: true,
      delivery_request: "",




      productRequestSelected: {},                           // Armazena o iten selecionado via requisição na lista de itens permitidos

      quantitySelectProduct: [],
      indice: 0,
      product_request: [],
      listProductsRequest: [],
      products: [],
      product: "",
      product_object: [],

      category: "",
      btn_name: "Adicionar",
      name_action_card: "",

      equipamento: "",
      saldo: 200,

      requests: "",
      delivery_product: true,
      delivery_request: "",
      list_products_delivery: "",
      autenticacao: "",
      current_page: 1,
      last_page: 0,
      page: 0,

      index_selecionado_delivery: -1,

      trava_one_request: 0,
      inputOutputSearch: 0
    };
  },
  components: {
    NavBar,
    TabsCard,
    EpiMenu,
  },
  created() {

    this.loader = true

    this.listProductsRoleUserPermission()

    //this.listProducts()

    //this.trava_one_request = 0

    //let url = window.location.href;

    //this.autenticacao = url.substring(url.indexOf("?") + 1, url.length);

    //this.equipamentos();
    //this.listproducts();

    //this.$store.commit("clear_product_request");
  },
  watch: {
    // whenever question changes, this function will run
    listcategory: function (newCategory, oldCategory) {
      //this.getitens()

    },
    /*search(newvalue, oldvalue) {
        this.products = []
        this.inputOutputSearch = 1
    }*/
  },
  computed: {
    products_delivery() {
      this.list_products_delivery = this.$store.getters.list_products;

      return this.list_products_delivery;
    },
    products_delivery_request() {
      this.list_products_delivery_request = this.$store.getters.list_products_request;

      return this.list_products_delivery_request;
    },
    listcategory() {
      this.category = this.$store.state.category;

      return this.category;

    },
  },
  methods: {
    /* ----- Altera a aba de itens para itens por regra ---------------------------------------- */
    abaProduct() {
      this.listProductsRoleUserPermission()

      this.delivery_product = true;
      this.delivery_request = false;
    },
    /* ----- Altera a aba de itens para itens requisitados ------------------------------------- */
    abaRequest() {
      this.listProductsRequestUserPermission()
      this.delivery_product = false;
      this.delivery_request = true;
    },
    /* ----- Lista todos os produtos permitidos para o colaborador em questão ------------------ */
    listProductsRoleUserPermission() {

      this.loader = true

      var user = JSON.parse(localStorage.getItem('userKey'))

      const method = "post";
      var rotas = "product_role_permission";

      const options = {
        headers: {
          "Accept": "application/json",
          Authorization: `Bearer ${user.token}`
        }
      }

      let matriculation = this.$store.state.matriculation;

      var data = {
        matriculation: matriculation,
        search: this.search,
        navio: user.local
      }

      axios[method](`${baseApiUrl}/${rotas}`, data, options, {
        timeout: 5000,
      }).then((res) => {

        this.loader = false

        console.log(res.data)

        this.collaborator = res.data.collaborator[0]

        this.listProductsRoleUser = res.data.listProductPermission

      }).catch((e) => {

        this.loader = false

        if (e.code === "ECONNABORTED") {
        }
      });
    },
    /* ----- Armazena o iten selecionado ------------------------------------------------------- */
    selectRoleProduct(iten, name_action, index) {

      this.index_selecionado = index,

        console.log(iten)

      this.productRoleSelected = iten;

      if (Object.keys(this.productRoleSelected).length > 0) {
        this.btnAdddisabled = false
      }
    },
    /* ----- Lista de itens para ser entregue via regra ---------------------------------------- */
    addDeliveryRole() {

      //console.log(this.productRoleSelected)

      //console.log(this.productRoleSelected.quantityDelivery)

      const needle = this.productsDeliveryRole.find((currObj) => {
        return currObj.productCode === this.productRoleSelected.productCode
      });

      if (needle) {

        this.index_selecionado = 1000000

        this.productRoleSelected = {}

        this.btnAdddisabled = true

        this.$toast.warning("Item já adicionado a lista de entrega", {
          timeout: 4000
        });
      } else {



        if (this.productRoleSelected.categoryQuantity == 0) {

          this.$toast.warning("Saldo insuficiente", {
            timeout: 4000
          });

          return;
        }

        if (Object.keys(this.productRoleSelected).length > 0) {

          this.productRoleSelected.quantityPermission = this.productRoleSelected.categoryQuantity
          this.productRoleSelected.quantityDelivery = 1

          this.productsDeliveryRole.push(this.productRoleSelected)                          // Adiciona o produto selecionado na lista de itens a ser entregue

          this.quantitySelectProductRole.push(
            {
              id: this.productRoleSelected.productId,
              quantity: 1
            })

          this.index_selecionado = 1000000

          this.productRoleSelected = {}

          this.btnAdddisabled = true
        } else {
          this.$toast.warning("Selecione um item!", {
            timeout: 4000
          });
        }
      }

      if (this.productsDeliveryRole.length > 0) {

        this.btnSaveRoledisabled = false
      }
    },
    /* ----- Altera a quantidade de itens a ser entregue via regra ----------------------------- */
    selectQuantityRoleItem(action, index, id) {

      console.log(action)
      console.log(index)
      console.log(id)
      console.log('------')
      console.log(this)
      if (action === 'less') {

        if (this.quantitySelectProductRole[index].quantity > 0 && this.quantitySelectProductRole[index].id === id) {

          let valor = parseInt(this.quantitySelectProductRole[index].quantity)

          valor = valor - 1;

          if (valor === 0) {

            this.deleteProductlistRole(index)
          }

          this.quantitySelectProductRole[index].quantity = valor.toString()

          this.productsDeliveryRole[index].quantityDelivery = this.quantitySelectProductRole[index].quantity
        }
      } else if (action === 'more') {

        this.quantitySelectProductRole[index].quantity = parseInt(this.quantitySelectProductRole[index].quantity)
        this.productsDeliveryRole[index].quantityPermission = parseInt(this.productsDeliveryRole[index].quantityPermission)

        if ((this.quantitySelectProductRole[index].id === id) && (this.quantitySelectProductRole[index].quantity < this.productsDeliveryRole[index].quantityPermission)) {

          let valor = parseInt(this.quantitySelectProductRole[index].quantity)

          valor = valor + 1;

          this.quantitySelectProductRole[index].quantity = valor.toString()

          this.productsDeliveryRole[index].quantityDelivery = this.quantitySelectProductRole[index].quantity

        } else {

          let description = parseInt(this.quantitySelectProductRole[index].quantity) > 1 ? 'Produtos' : 'Produto'

          this.$toast.warning(`Quantidade máxima permitida: ${this.quantitySelectProductRole[index].quantity} ${description} !`, {
            timeout: 4000
          });
        }
      }
    },
    /* ----- Realiza a entrega dos epis selecionados regra ------------------------------------- */
    deliveryRole() {

      this.loaderdelivery1 = true

      this.btnAdddisabled = true
      this.btnSaveRoledisabled = true
      this.btnCanceldisabled = true

      var user = JSON.parse(localStorage.getItem('userKey'))

      const method = "post";
      var rotas = "deliveryRole";

      const options = {
        headers: {
          Accept: "application/json",
          //Authorization: `Bearer ${user.token}`
        },
      };

      let company = 'Technip'
      let company_unit = this.collaborator.company_unitsFantasyName
      let point_delivery = user.local
      let sector = this.collaborator.sectorName
      let collaborator = this.collaborator.name
      let gender = this.collaborator.gender
      let group = this.collaborator.groupName
      let role = this.collaborator.roleName
      let matriculation = this.collaborator.matriculation
      let operador = user.user

      const now = new Date()

      function adicionaZero(numero) {
        if (numero <= 9)
          return "0" + numero;
        else
          return numero;
      }

      let ano = now.getFullYear()
      let mes = adicionaZero((now.getMonth() + 1).toString())
      let dia = adicionaZero(now.getDate().toString())

      let data = ano + '-' + mes + '-' + dia

      let hora = now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds()

      function adicionaZero(numero) {
        if (numero <= 9)
          return "0" + numero;
        else
          return numero;
      }

      let productRole = this.productsDeliveryRole.map(filter_product);

      function filter_product(item) {
        return {
          equipament_model: 'Online',
          point_delivery: point_delivery,
          company: company,
          company_unit: company_unit,
          sector: sector,
          collaborator: collaborator,
          matriculation: matriculation,
          gender: gender,
          ghe: group,
          function: role,
          code_product: item.productCode,
          product: item.productName,
          ca: item.productCa,
          category: item.categoryName,
          size: item.sizeName,
          quantity: item.quantityDelivery,
          identification: 'Matrícula',
          autentication: 'Senha',
          date_delivery: data,
          hour_delivery: hora,
          type_delivery: 'Regra',
          operator: operador,
          justification: 'Não se aplica'
        };
      }

      console.log(productRole)

      axios[method](`${baseApiUrl}/${rotas}`, productRole, options, {
        timeout: 5000,
      }).then((res) => {

        this.loaderdelivery1 = false

        console.log(res.data)

        this.productsDeliveryRole = []
        this.productRoleSelected = {}
        this.quantitySelectProductRole = []

        this.index_selecionado = 1000000

        this.productRoleSelected = {}

        this.btnAdddisabled = true

        this.$toast.success("Operação realizada com sucesso", {
          timeout: 4000
        });

        this.listProductsRoleUserPermission()

        const myTimeout = setTimeout(myGreeting, 4000);

        var that = this

        function myGreeting() {



          that.btnAdddisabled = false
          that.btnSaveRoledisabled = true
          that.btnCanceldisabled = false
        }

        /*if (res.data.delivery) {

          //this.$store.commit("clear_product", this.product.code);
          this.$store.commit("clear_product");

          this.$toasted.global.defaultSuccess({ msg: "" });

          this.loaderdelivery = false

          this.msg_success = "Operação realizada com sucesso!";

        }*/

        //this.listproducts();
      })
        .catch((e) => {

          this.loaderdelivery1 = false

          this.btnAdddisabled = false
          this.btnSaveRoledisabled = false
          this.btnCanceldisabled = false


          if (e.code === "ECONNABORTED") {
          }
        });

      //this.msg_error = ""

      //this.loaderdelivery = true

      //var id_user = localStorage.getItem('collaboratorKey');
      //var user_delivery_info = JSON.parse(localStorage.getItem('userKey'));

      //var operator = JSON.parse(localStorage.getItem("userKey"));

      //var equipament = this.equipamento;
      //var autenticacao = this.autenticacao

      //var list_products_delivery = this.$store.getters.list_products;

      //console.log(list_products_delivery.length)
      /*if(list_products_delivery.length <= 0){

        this.loaderdelivery = false

        this.msg_success = ""

        this.msg_error = "Selecione um produto!";
        return;
      }*/

      //var product = list_products_delivery.map(filter_product);

      /*function filter_product(item) {
        return {
          code_product: item.product_code,
          description_product: item.product_product,
          quantity_delivery_product: item.users_quantitys_periods_quantity,
          ca_product: item.product_ca,
          product_id: item.product_id_web,
          autenticacao: 'senha',
          user_id: id_user,
          ponto_entrega: user_delivery_info.local_embarcacao,
          equipamento: 'balcao_online',
          operador:user_delivery_info.user,
          matricula_operador: user_delivery_info.matricula
        };
      }*/
    },
    /* ----- Exclui um item da lista de itens a ser entregue via regra ------------------------- */
    deleteProductlistRole(index, id) {

      this.productsDeliveryRole.splice(index, 1)
      this.quantitySelectProductRole.splice(index, 1)

      if (this.productsDeliveryRole.length === 0) {

        this.btnSaveRoledisabled = true
      }
    },
    /* ----- Lista todos os produtos requisitados para o colaborador em questão ---------------- */
    listProductsRequestUserPermission() {

      var user = JSON.parse(localStorage.getItem('userKey'))

      const method = "post";
      var rotas = "delivery_request_collaborator";

      const options = {
        headers: {
          "Accept": "application/json",
          Authorization: `Bearer ${user.token}`
        }
      }

      var matriculation = '7310';

      var data = {
        matriculation: matriculation,
        page: this.page,
        itensPerPage: 10
      }

      axios[method](`${baseApiUrl}/${rotas}`, data, options, {
        timeout: 5000,
      }).then((res) => {

        //console.log(res.data)

        this.listProductsRequestUser = res.data.resultDeliveryRequest;

      })
        .catch((e) => {

          this.loader = false

          if (e.code === "ECONNABORTED") {
          }
        });
    },
    /* ----- Armazena o iten selecionado ------------------------------------------------------- */
    selectProductRequest(iten, name_action, index) {

      this.productRequestSelected = iten;
    },
    /* ----- Lista de itens para ser entregue via requisição ----------------------------------- */
    addDeliveryRequest() {

      this.productRequestSelected.quantityPermission = this.quantityPermission

      this.productsDeliveryRequest.push(this.productRequestSelected)

      this.quantitySelectProductRequest.push(
        {
          id: this.productRequestSelected.id,
          quantity: 1
        })
    },
    /* ----- Exclui um item da lista de itens a ser entregue via requisição -------------------- */
    deleteProductlistRequest(index, id) {

      console.log(index)

      this.productsDeliveryRequest.splice(index, 1)
      //this.quantitySelectProduct.splice(index, 1)

    },
    /* ----- Altera a quantidade de itens a ser entregue via requisição ------------------------ */
    selectQuantityRequestItem(action, index, id) {

      if (action === 'less') {

        if (this.quantitySelectProductRequest[index].quantity > 0 && this.quantitySelectProductRequest[index].id === id) {

          let valor = parseInt(this.quantitySelectProductRequest[index].quantity)

          valor = valor - 1;

          this.quantitySelectProductRequest[index].quantity = valor.toString()
        }
      } else if (action === 'more') {

        if (this.quantitySelectProductRequest[index].id === id) {

          let valor = parseInt(this.quantitySelectProductRequest[index].quantity)

          valor = valor + 1;

          this.quantitySelectProductRequest[index].quantity = valor
        }
      }
    },
    /* ----- Realiza a entrega dos epis selecionados via requisição ---------------------------- */
    deliveryRequest() {

      var user = JSON.parse(localStorage.getItem('userKey'))

      const method = "post";
      var rotas = "delivery_request";

      const options = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`
        },
      };

      //console.log(this.productsDeliveryRequest);

      let productRequest = this.productsDeliveryRequest.map(myFunction);

      console.log(productRequest)

      function myFunction(iten) {
        return {
          product_name: iten.name_product,
          quantity: iten.quantity_request_product
        };
      }

      /*const data = {
        product_name: this.productsDeliveryRequest.name_product,
        quantity: this.productsDeliveryRequest.quantity_request_product
      }*/

      axios[method](`${baseApiUrl}/${rotas}`, productRequest, options, {
        timeout: 5000,
      })
        .then((res) => {

          console.log(res.data)

          /*this.$store.commit("clear_product_request");

          this.$toasted.global.defaultSuccess({ msg: "" });

          this.trava_one_request = 0

          this.msg_success = "Operação realizada com sucesso!";*/

          //this.listproducts();
        })
        .catch((e) => {

          console.log(e)
          if (e.code === "ECONNABORTED") {
          }
        });
    },
    close() {
      this.$router.push({
        path: `/`,
      });
    },





    /*if (this.name_action_card == "list") {
      this.msg_warning = "";
    
      this.btn_name = "Adicionar";
      document.getElementById("btn_item").style.backgroundColor = "#0d6efd";
      document.getElementById("btn_item").style.borderColor = "#0d6efd";
    
      if(this.trava_one_request == 0){
    
        this.trava_one_request = 1
    
        this.product_request = {
          ...this.product_request,
          users_quantitys_periods_quantity: this.quantity_product,
        };
        
    
        console.log(this.product_request)
    
        this.msg_success = "";
    
        this.$store.commit("add_product_request", this.product_request);
        
      }
    
    } else if (this.name_action_card == "basket") {
      this.btn_name = "Remover";
      document.getElementById("btn_item").style.backgroundColor = "red";
      document.getElementById("btn_item").style.borderColor = "red";
    
      this.$store.commit(
        "remove_product_request",
        this.product_request.code_product
      );
    }*/
  },

































  /*-------------------- Lista todos os produtos permitidos para o usuário --------------------*/
  listproducts2() {

    if (this.inputOutputSearch > 0) {
      this.page = 1
      this.inputOutputSearch = 0
    }

    this.loader = true

    const method = "post";
    var rotas = "list_products_people";

    const options = {
      headers: {
        Accept: "application/json",
      },
    };

    var matriculation = this.$store.state.matriculation;

    var params = {
      matriculation: matriculation,
      search: this.search,
      page: this.page,
      itensPerPage: 10
    }

    axios[method](
      `${baseApiUrl}/${rotas}`, params,
      {
        timeout: 35000,
      },
      options
    )
      .then((res) => {

        console.log(res.data)

        if (this.search.length > 0) {

          this.products = []

        }

        localStorage.setItem('collaboratorKey', JSON.stringify(res.data.id_user))

        //this.products.push(...res.data.list_products.data);

        this.products.push(...res.data.list_products);

        //this.current_page = res.data.list_products.current_page
        //this.last_page = res.data.list_products.last_page

        this.requests = res.data.list_requests;

        this.loader = false

      })
      .catch((e) => {

        this.loader = false

        if (e.code === "ECONNABORTED") {
        }
      });
  },
  /* listproductsSearch() {

     this.page = 1

     this.loader = true

     const method = "post";
     var rotas = "list_products_people";

     const options = {
       headers: {
         Accept: "application/json",
       },
     };

     var matriculation = this.$store.state.matriculation;

     var params = {
       matriculation: matriculation,
       search: this.search,
       page: this.page
     }

     axios[method](
       `${baseApiUrl}/${rotas}`, params,
       {
         timeout: 35000,
       },
       options
     )
       .then((res) => {

         console.log(res.data)

         localStorage.setItem('collaboratorKey', JSON.stringify(res.data.id_user))

         this.products = []

         this.products.push(...res.data.list_products.data);

         this.current_page = res.data.list_products.current_page
         this.last_page = res.data.list_products.last_page

         this.requests = res.data.list_requests;

         this.loader = false

       })
       .catch((e) => {

         this.loader = false

         if (e.code === "ECONNABORTED") {
         }
       });
   },*/
  /*select_product(image, name_action, index) {

    this.name_action_card = name_action;

    if (name_action == "list") {
      
      this.index_selecionado = index
      this.index_selecionado_delivery = -1

      this.btn_name = "Adicionar";
      document.getElementById("btn_item").style.backgroundColor = "#0d6efd";
      document.getElementById("btn_item").style.borderColor = "#0d6efd";

    
    } else if (name_action == "basket") {

      this.index_selecionado = -1
      this.index_selecionado_delivery = index

      this.btn_name = "Remover";
      document.getElementById("btn_item").style.backgroundColor = "red";
      document.getElementById("btn_item").style.borderColor = "red";
    }

    this.product = image;

    //console.log(this.product)

    if (image.product_id == 1) {
      this.caminho = "perfil.png";
    }
    if (image.product_id == 2) {
      this.caminho = "protetor-auricular.jpeg";
    }
  },*/



  /*delivery() {

    this.msg_error = ""

    this.loaderdelivery = true

    var id_user = localStorage.getItem('collaboratorKey');
    var user_delivery_info = JSON.parse(localStorage.getItem('userKey'));

    var operator = JSON.parse(localStorage.getItem("userKey"));
    
    var equipament = this.equipamento;
    var autenticacao = this.autenticacao

    var list_products_delivery = this.$store.getters.list_products;

    console.log(list_products_delivery.length)
    if(list_products_delivery.length <= 0){

      this.loaderdelivery = false

      this.msg_success = ""

      this.msg_error = "Selecione um produto!";
      return;
    }
    
    var product = list_products_delivery.map(filter_product);

    function filter_product(item) {
      return {
        code_product: item.product_code,
        description_product: item.product_product,
        quantity_delivery_product: item.users_quantitys_periods_quantity,
        ca_product: item.product_ca,
        product_id: item.product_id_web,
        autenticacao: 'senha',
        user_id: id_user,
        ponto_entrega: user_delivery_info.local_embarcacao,
        equipamento: 'balcao_online',
        operador:user_delivery_info.user,
        matricula_operador: user_delivery_info.matricula
      };
    }

    const method = "post";
    var rotas = "add_delivery";

    const options = {
      headers: {
        Accept: "application/json",
      },
    };

    axios[method](
      `${baseApiUrl}/${rotas}`,
      product,
      {
        timeout: 5000,
      },
      options
    )
      .then((res) => {

        //console.log(res.data)

        if (res.data.delivery) {

          //this.$store.commit("clear_product", this.product.code);
          this.$store.commit("clear_product");

          this.$toasted.global.defaultSuccess({ msg: "" });

          this.loaderdelivery = false

          this.msg_success = "Operação realizada com sucesso!";

        }

        //this.listproducts();
      })
      .catch((e) => {

        this.loaderdelivery = false


        if (e.code === "ECONNABORTED") {
        }
      });
  },*/

  getitens() {
    var listproducts;

    /*if (this.search && this.category && this.category != "All") {
      listproducts = this.products.filter(
        (products) =>
          products.product_category.includes(this.category) &&
          products.product_product.includes(this.search)
      );
    } else if (this.search) {
      listproducts = this.products.filter((products) =>
        products.product_product.includes(this.search)
      );
    } else if (this.category && this.category != "All") {
      listproducts = this.products.filter((products) =>
        products.product_category.includes(this.category)
      );
    } else {
      listproducts = this.products;
    }*/

    listproducts = this.products;

    return listproducts;
  },
  cancel() {

    this.trava_one_request == 0

    this.$router.push({
      path: `/`,
    });
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.pre_loader {
  position: absolute;
  top: calc(50% - 35px);
  left: calc(50% - 133px);
}

.pre_loader_delivery {
  position: absolute;
  top: calc(50% - 35px);
  left: calc(50% - 133px);
}

.btn_pre_loader {
  display: flex;
  align-items: center;
  padding: 25px 25px;
  font-size: 25px;
  background-color: #6237A0 !important;
  opacity: 1 !important;
}

.btn_pre_loader,
.btn_pre_loader:focus {
  outline: 0 !important;
  border: none !important;
}

.btn_pre_loader span {
  padding: 8px;
  margin-right: 15px;
}

/*====================================================================================================*/

.destaque {
  background-color: rgba(126, 91, 211, 1);
  color: #fff;
  border-radius: 10px;
}

/*========================================================================================================================*/
/*                                                   Page content                                                         */
/*========================================================================================================================*/
.withdraw {
  display: flex;
}

/*========================================================================================================================*/
/*                                                Left category column                                                    */
/*========================================================================================================================*/
.left-menu {
  height: calc(100vh - 45px);
  background-color: #222e3c;
  width: 90px;
  color: rgba(255, 255, 255, 0.95);
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2);
}

/*========================================================================================================================*/
/*                                                  Content products                                                      */
/*========================================================================================================================*/
.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
}

/*========================================================================================================================*/
/*                                                        Card                                                            */
/*========================================================================================================================*/
/*==================================================== Card header =======================================================*/
.card .card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7e5bd3;
}

/*------------------------------------------------- Card header title ----------------------------------------------------*/
.card .card-header h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  margin: 5px 0px;
}

/*==================================================== Card body =========================================================*/
/*======================================================= Body ===========================================================*/
.card-body {
  padding: 0px 7px;
}

/*===================================================== products =========================================================*/
.products-list {
  height: 70vh;
  overflow-y: scroll;
}

.products-delivery {
  height: 68vh;
  overflow-y: scroll;
}

::-webkit-scrollbar-track {
  background-color: #e4e6e7;
  border-radius: 20px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #7e5bd3;
  border-radius: 20px;
  border: 3px solid transparent;
}

tr a {
  cursor: pointer;
}

/*==================================================== Card footer =======================================================*/
.card .card-footer {
  background-color: #fff;
}

/*--------------------------------------------------- Content product ----------------------------------------------------*/
.content-product {
  display: flex;
  margin-top: 10px;
  margin-bottom: 6px;
  align-items: center;
}

.content-product img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
}

.content-product .info {
  width: 85%;
}

.content-product .info p {
  margin-bottom: 0px;
  text-align: left;
  font-size: 15px;
  line-height: 16px;
}


.content-product .quantity-delivery-request,
.content-product .quantity-delivery-role {
  display: flex;
  flex-direction: column;
  width: 35%;
  margin-right: 3px;
}

.content-product .quantity_list_green_list,
.content-product .quantity_list_red_list,
.content-product .quantity-delivery_delivery {
  display: flex;
  width: 12%;
}

.content-product .quantity_list_green,
.content-product .quantity_list_red,
.content-product .quantity-delivery {
  display: flex;
  width: 98%;
}

.content-product .quantity-delivery .quantity-select {
  width: 100%;
  margin: 0 4px;
}

.content-product .quantity-delivery .btn-quantity-less,
.content-product .quantity-delivery .btn-quantity-less:focus {
  font-weight: 700;
  font-size: 15px;
  outline: none;
  box-shadow: none;
}

.content-product .quantity-delivery .btn-quantity-more,
.content-product .quantity-delivery .btn-quantity-more:focus {
  font-weight: 700;
  font-size: 15px;
  padding: 5px;
  outline: none;
  box-shadow: none;
}

.content-product .quantity-delete .btn-delete {
  text-decoration: none !important;
  font-size: 13px;
  padding: 0px 0px !important;
  color: red;
  font-weight: 600 !important;

}

.content-product .quantity_list_green_list p {
  padding: 0px 12px;
  color: #fff;
  background-color: green;
  border-radius: 10px;
  margin-bottom: 0px;
  font-size: 17px;
  font-weight: 600;
}


.content-product .quantity_list_green p {
  padding: 0px 12px;
  color: #fff;
  background-color: green;
  border-radius: 10px;
  margin-bottom: 0px;
  font-size: 17px;
  font-weight: 600;
}

.content-product .quantity_list_red p {
  padding: 0px 12px;
  color: #fff;
  background-color: red;
  border-radius: 10px;
  margin-bottom: 0px;
  font-size: 17px;
  font-weight: 600;
}

.content-product .quantity-delivery p {
  padding: 0px 12px;
  color: #fff;
  background-color: #00aaff;
  border-radius: 10px;
  margin-bottom: 0px;
  font-size: 17px;
  font-weight: 600;
}

/*========================================================================================================================*/
/*                                                    Card list                                                           */
/*========================================================================================================================*/
.body-list-product {
  width: 380px;
  padding: 0px;
}

.body-list-product .search {
  display: flex;
  margin-top: 10px;
  padding: 10px;
}

.input-search {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.btn-search {
  border: none;
  color: #fff;
  background-color: #6237A0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.body-list-product .container {
  padding: 5px;
}

.body-list-product .nav-link {
  color: #a9a9a9;
  border: none;
}

.body-list-product .nav-link.active {
  background-color: #7e5bd3;
  color: #fff;
}

.nav-tab button.active {
  background-color: #7e5bd3 !important;
}

/*========================================================================================================================*/
/*                                                    Card image                                                          */
/*========================================================================================================================*/
/*====================================================== Card ============================================================*/
.body-image {
  width: 400px;
}

/*====================================================== Body ============================================================*/
.body-image .image-product {
  margin-top: 20px;
}

.body-image .image-product img {
  width: 300px;
  height: 300px;
}

.card-body .control {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.body-image label {
  margin-top: 10px;
  margin-bottom: 5px;
}

.body-image label.msg-warning {
  color: #ff0000;
  font-size: 20px;
  font-weight: 600;
}

.body-image .quantity-add {
  width: 80%;
}

.body-image .btn-add {
  width: 80%;
}

.body-image .btn-add,
.body-image .btn-add:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
}

/*========================================================================================================================*/
/*                                                   Card delivery                                                        */
/*========================================================================================================================*/
/*======================================================= Card ===========================================================*/
.body-delivery {
  padding-top: 10px;
  padding-bottom: 5px;
  width: 420px;
}

.products-delivery label.msg-success {
  color: green;
  font-size: 20px;
  font-weight: 600;
}

.products-delivery label.msg-error {
  color: red;
  font-size: 20px;
  font-weight: 600;
}

/*====================================================== Footer ==========================================================*/
/*------------------------------------------------------ Button ----------------------------------------------------------*/
.card .card-footer .menu-button {
  display: flex;
  justify-content: space-around;
}

.card .card-footer .menu-button button {
  color: #fff;
  cursor: pointer;
  transition: 0.1s;
  padding: 12px 50px;
}

.card .card-footer .menu-button .btn-basket {
  display: flex;
  align-items: center;
  background-color: #a9a9a9;
  border: none;
}

.card .card-footer .menu-button .btn-basket:hover {
  background-color: #808080;
}

.card .card-footer .menu-button .btn-basket span .fa-shopping-basket {
  font-size: 30px;
  margin-right: 5px;
}

.card .card-footer .menu-button .btn-save {
  display: flex;
  align-items: center;
  background-color: #00aaff;
  border: none;
}

.card .card-footer .menu-button .btn-save:hover {
  background-color: #0083ff;
}

.card .card-footer .menu-button .btn-save span .fa-save {
  font-size: 30px;
  margin-right: 5px;
}

.card .card-footer .menu-button .btn-exit {
  display: flex;
  align-items: center;
  background-color: #e32636;
  border: none;
}

.card .card-footer .menu-button .btn-exit:hover {
  background-color: #b62937;
}

.card .card-footer .menu-button .btn-exit span .fa-window-close {
  font-size: 30px;
  margin-right: 5px;
}
</style>

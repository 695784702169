<template>
	<div class="page">
		<div class="chart-card">
			<h2 class="title">Métricas</h2>
			<div class="pie-charts-container">
				<Pie
					:chartLabels="pieChart1.labels"
					:chartNumbers="pieChart1.numbers"
					:chartColors="pieChart1.colors"
					chartTitle="Entregas por Regra"
					chartId="pie-chart1"
				/>
				<Pie
					:chartLabels="pieChart2.labels"
					:chartNumbers="pieChart2.numbers"
					:chartColors="pieChart2.colors"
					chartTitle="Entregas por Requisições"
					chartId="pie-chart2"
				/>
				<Pie
					:chartLabels="pieChart3.labels"
					:chartNumbers="pieChart3.numbers"
					:chartColors="pieChart3.colors"
					chartTitle="Devolução de itens"
					chartId="pie-chart3"
				/>
			</div>
		</div>
		<div class="chart-card">
			<h2 class="title">Operações por produto</h2>
			<div class="bar-container">
				<div class="bar-extensor">
					<Bar
						:chartLabels="barChart.labels"
						:chartNumbers="barChart.numbers"
						:chartColors="barChart.colors"
						chartTitle="Contribuidores de maior impacto"
						chartId="bar-chart"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Pie from "@/components/Pie";
import Bar from "@/components/Bar";

export default {
	name: "ChartsPage",
	data() {
		return {
			pieChart1: {},
			pieChart2: {},
			pieChart3: {},
			barChart: {},
		};
	},
	components: {
		Pie,
		Bar,
	},
	methods: {
		createPieChart1() {
			this.pieChart1.numbers = [5, 9, 5, 5];
			this.pieChart1.colors = ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9"];
			this.pieChart1.labels = [
				"Pomada",
				"Máscara",
				"Protetor Auricular",
				"Óculos",
			];
		},
		createPieChart2() {
			this.pieChart2.numbers = [5, 9, 3, 5];
			this.pieChart2.colors = ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9"];
			this.pieChart2.labels = [
				"Pomada",
				"Máscara",
				"Protetor Auricular",
				"Óculos",
			];
		},
		createPieChart3() {
			this.pieChart3.numbers = [2, 9, 1, 5];
			this.pieChart3.colors = ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9"];
			this.pieChart3.labels = [
				"Pomada",
				"Máscara",
				"Protetor Auricular",
				"Óculos",
			];
		},
		createBarChart() {
			this.barChart.numbers = [20, 15, 12, 10, 10, 10, 5, 2];
			this.barChart.colors = [
				"#F37784",
				"#AAE6F7",
				"#F6F794",
				"#AAE56C",
				"#A18BCC",
				"#F7A444",
				"#F6AAC4",
				"#C6DDE7",
			];
			this.barChart.labels = [
				"Máscara",
				"Óculos",
				"Protetor Auricular",
				"Luva",
				"Avental",
				"Calça de Proteção",
				"Respirador",
				"Pomada",
			];
		},
	},
	created() {
		this.createPieChart1();
		this.createPieChart2();
		this.createPieChart3();
		this.createBarChart();
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page {
	width: 80%;
	padding-right: 10%;
	padding-left: 10%;
	height: 100%;
	display: grid;
	grid-template-columns: 2fr 5fr;
}

.chart-card {
	background-color: #fff;
	margin: 10px;
	border-radius: 5px;
	box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
}

.title {
	text-align: left;
	margin: 10px 20px 0 20px;
	padding-bottom: 10px;
	font-weight: 400;
	border-bottom: 1px solid;
	font-size: 20px;
}

.pie-charts-container {
	height: calc(100% - 48px);
	display: grid;
}

.bar-container {
	width: 100%;
	padding: 10px 20px;
	box-sizing: border-box;
	height: calc(100% - 48px);
}

.bar-container::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

/* Track */
.bar-container::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
}

/* Handle */
.bar-container::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.15);
	border-radius: 10px;
}

@media only screen and (max-width: 1200px) {
	.page {
		grid-template-columns: 1fr;
		height: auto;
		overflow-y: scroll;
		padding-top: 3%;
		padding-bottom: 3%;
	}

	.pie-charts-container {
		width: auto;
		padding-bottom: 20px;
		height: auto;
	}
}

@media only screen and (max-width: 500px) {
	.bar-container {
		overflow-x: scroll;
		overflow-y: hidden;
		width: calc(100% - 20px);
		margin: 0 10px;
		box-sizing: border-box;
	}
	.bar-extensor {
		width: 800px;
	}
	.chart-card {
		width: calc(80vw - 20px);
	}
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state:{
        product:[],
        product_request:[],
        category:'',
        matriculation:'',
        listPeople:[]
    },
    mutations:{
        add_product(state, product){

            var existe_product = false
            var position = 0

            if(state.product.length > 0){

                for(var i = 0; i < state.product.length; i++) {

                    if(state.product[i].product_code == product.product_code) { 

                        existe_product = true
                        position = i
    
                    }
                }  

                if(existe_product == true){

                    var quantity_product_vuex = parseInt(state.product[position].users_quantitys_periods_quantity)

                    quantity_product_vuex = quantity_product_vuex +  parseInt(product.users_quantitys_periods_quantity)
                       
                    state.product[position].users_quantitys_periods_quantity = quantity_product_vuex.toString()

                    quantity_product_vuex = 0

                }
                if(existe_product == false){

                    console.log('add')

                    state.product.push(product)
                }
            }else{
    
                state.product.push(product)
            }
        },
        remove_product(state, product_code){

            var quantity

            for(var i = 0; i < state.product.length; i++) {

                if(state.product[i].product_code === product_code) {

                    quantity = parseInt(state.product[i].users_quantitys_periods_quantity)

                    if(quantity > 1){

                        quantity = quantity - 1

                        state.product[i].users_quantitys_periods_quantity = quantity.toString()

                    }else if(quantity == 1){

                        state.product.splice(i, 1)
                    }
                }
            }
        },
        remove_product_request(state, product_request_code){

            var quantity

            for(var i = 0; i < state.product_request.length; i++) {

                if(state.product_request[i].code_product === product_request_code) {

                    quantity = parseInt(state.product_request[i].users_quantitys_periods_quantity)

                    if(quantity > 1){

                        quantity = quantity - 1

                        state.product_request[i].users_quantitys_periods_quantity = quantity.toString()

                    }else if(quantity == 1){

                        state.product_request.splice(i, 1)
                    }
                }
            }
        },
        clear_product(state){

            console.log('limpou')

            state.product = []

            //state.product.splice(0, state.product.length)
        },
        clear_product_request(state){

            state.product_request.splice(0, state.product_request.length)
        },
        add_product_request(state, product_request){

            var existe_product_request = false
            var position = 0

            if(state.product_request.length > 0){

                for(var i = 0; i < state.product_request.length; i++) {

                    if(state.product_request[i].code_product == product_request.code_product) { 

                        existe_product_request = true
                        position = i

                        console.log('entrou')
    
                    }
                }  

                /*if(existe_product_request == true){

                    console.log('saldo')

                    var quantity_product_request_vuex = parseInt(state.product_request[position].users_quantitys_periods_quantity)

                    quantity_product_request_vuex = quantity_product_request_vuex +  parseInt(product_request.users_quantitys_periods_quantity)
                       
                    state.product_request[position].users_quantitys_periods_quantity = quantity_product_request_vuex.toString()

                    quantity_product_request_vuex = 0

                }*/
                if(existe_product_request == false){

                    console.log('add')

                    state.product_request.push(product_request)
                }
            }else{
    
                state.product_request.push(product_request)
            }
        },
    },
    getters:{
        list_products(state){
            return state.product
        },
        list_products_request(state){
            return state.product_request
        }
    }
})

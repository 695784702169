<template>
<div>
    <NavBar />
    <div class="redirect">
        <div class="redirect-card">
            <h2 class="redirect-title" :class="textColor">
                <div>{{ redirectText }}</div>
                <div class="spinner-line">
                    <div class="spinner-border text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="msg_redirect">
                        Cadastro realizado com sucesso!
                    </div>
                </div>
            </h2>
            <!--<Button buttonText="Redirecionar Agora" linkTo="/id" />-->
        </div>
    </div>
    <Footer />
</div>
</template>

<script>

import {redirectUrl } from "../global";

import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import Button from "@/components/Button";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
    name: "Redirect",
    components: {
        NavBar,
        Footer,
        Button,
        ClipLoader
    },
    data() {
        return {
            redirectText: "",
            textColor: "",
            colorCode: "",
        };
    },
    methods: {},
    created() {
        let url = window.location.href;
        let color = url.substring(url.indexOf("#") + 1, url.length);
        url = url.substring(url.indexOf("?") + 1, url.indexOf("#"));
        url = decodeURI(url);
        this.redirectText = url;
        this.textColor = color;

        if (color == "green") {
            this.colorCode = "#28A745";
        } else {
            this.colorCode = "#dc3545";
        }
    },
    mounted() {
        let time_redirect = setTimeout(function () {
            window.location.href = `${redirectUrl}`;
        }, 2000);
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.redirect {
    height: calc(100vh - 200px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.redirect-card {
    transition: 1s;
    min-width: 250px;
    width: 40%;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 3vw;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.redirect-title {
    color: rgba(0, 0, 0, 0.95);
    width: 100%;
    font-size: 28px;
    text-align: center;
    font-weight: 400;
    padding: 20px 10px;
}

.green {
    color: rgb(40, 167, 69);
}

.loader {
    display: flex;
    margin-right: 5px;
}

.msg_redirect {
    color: #008000;
    font-weight: 600;
    font-size:25px;
}

.red {
    color: rgb(220, 53, 69);
}

.input-container {
    margin-bottom: 15px;
}

.redirect-form {
    margin-bottom: 15px;
}

.btn-container:not(:last-child) {
    margin-bottom: 15px;
}

.spinner-line {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner-line .msg_redirect {
    margin-left: 15px;
}

@media only screen and (max-width: 1200px) {
    .redirect-card {
        padding: 4vw;
        width: 60%;
    }
}
</style>

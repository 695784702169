<template>
	<div class="main">
		<NavBar />
		<div class="main">
			<Slider />
		</div>
		<Footer />
	</div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import Slider from "@/components/Slider";

export default {
	name: "Main",
	components: {
		NavBar,
		Footer,
		Slider,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
